import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: false,
    description: false,
    background: true,
    positionBackground: true,
    logoImage: true,
    logoDimensions: true,
    widgetBackgroundImage: false,
    widgetPartBackgroundImage: false,
    headerBackground: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      image: false,
      colors: true,
      innerPageEnabled: true,
      groupTitle: true,
      url: true,
      templateOptions: false,
    },
    groups: false,
    templateOptions: {
      flipGradient: true,
      isInactivePhaseBlured: false,
      widgetSize: false,
    },
    zeroPhase: false,
  },
  colors: {
    page: [
      { title: 'Page background', defaultValue: 'linear-gradient(180deg, #f794a4 0%, #fdd6bd 100%)' },
      { title: 'Widget logo background', defaultValue: '#ffffff' },
      { title: 'Ring 1', defaultValue: 'rgba(255, 255, 255, .1)' },
      { title: 'Ring 2', defaultValue: 'rgba(255, 255, 255, .3)' },
      { title: 'Ring 3', defaultValue: 'rgba(255, 255, 255, .5)' },
      { title: 'Ring 4', defaultValue: 'rgba(255, 255, 255, .7)' },
      { title: 'Ring 5', defaultValue: 'rgba(255, 255, 255, .9)' },
      { title: 'Widget Border', defaultValue: 'rgba(0, 0, 0, 0)' },
    ],
    button: [
      { title: 'Background', defaultValue: '#0014cc' },
      { title: 'Text', defaultValue: '#fff' },
    ],
    phase: [
      { title: 'Overlay background', defaultValue: '#0014cc' },
      { title: 'Point', defaultValue: '#0014CC' },
      { title: 'Title & descr.', defaultValue: '#ffffff' },
      { title: 'Overlay text', defaultValue: '#ffffff' },
      { title: 'Logo background', defaultValue: '#fff' },
      { title: 'Group title', defaultValue: '#fafafa' },
      { title: 'Description background', defaultValue: 'rgba(255, 255, 255, 0)' },
    ],
    group: [],
  },
}
