import { MayBeNull } from '@wpp-open/core'
import { MouseEventHandler, useMemo, useCallback } from 'react'

import styles from 'pages/processBuilder/templates/circular5/group/Group.module.scss'
import { PhasePoint } from 'pages/processBuilder/templates/circular5/phase/Phase'
import { getPieItemPath, getCurvedTextPath } from 'pages/processBuilder/utils'
import { FormPhase, FormGroup } from 'types/process/process'

export const Group = ({
  group,
  phases,
  isActive,
  activePhase,
  activeGroup,
  rotation,
  size,
  groupIndex,
  onGroupEnter,
  onGroupLeave,
  onPhaseEnter,
  onPhaseLeave,
}: {
  group: FormGroup
  phases: FormPhase[]
  isActive: boolean
  activePhase: MayBeNull<number>
  activeGroup: MayBeNull<number>
  rotation: number
  size: number
  groupIndex: number
  onGroupEnter: MouseEventHandler
  onGroupLeave: MouseEventHandler
  onPhaseEnter: (point: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
}) => {
  const groupPhases = useMemo(
    () =>
      group?.phasesLocalIds?.reduce((accum: FormPhase[], groupPhaseId: string) => {
        const phase = phases.find(({ localId }) => localId === groupPhaseId)
        phase && accum.push(phase)

        return accum
      }, []),
    [group.phasesLocalIds, phases],
  )

  const isTitleReverted = rotation > 90 && rotation < 270

  const pathData = getPieItemPath({
    radius: 302,
    size,
    x: 640,
    y: 455,
  })

  const isActivePhase = useCallback((index: number) => index === activePhase, [activePhase])

  return (
    <>
      <g
        onMouseOver={onGroupEnter}
        onMouseLeave={onGroupLeave}
        transform={`rotate(${rotation} 640 455)`}
        data-testid="circular-five-group"
      >
        <path d={pathData} fill="transparent" />

        <text alignmentBaseline="middle" className={styles.title}>
          <textPath
            xlinkHref={`#groupTextPath_${groupIndex}`}
            fill={group?.colors?.[0] || '#001023'}
            fillOpacity={!Number.isInteger(activeGroup) || isActive ? 1 : 0}
            startOffset="50%"
            textAnchor="middle"
            alignmentBaseline="central"
            data-testid="group-title"
          >
            {group?.title}
          </textPath>
        </text>

        <g opacity={isActive || !Number.isInteger(activeGroup) ? 1 : 0} data-testid="group-phase-point-container">
          {groupPhases?.map((phase, index) => (
            <PhasePoint
              key={index}
              phase={phase}
              phasesCount={groupPhases.length}
              groupSize={size}
              groupIndex={groupIndex}
              index={index}
              activePhase={activePhase}
              isActive={isActivePhase(index)}
              groupRotation={rotation}
              onPhaseEnter={onPhaseEnter(index)}
              onPhaseLeave={onPhaseLeave}
            />
          ))}
        </g>
      </g>
      <defs>
        <path
          d={getCurvedTextPath({ radius: 270, x: 640, y: 455, isReversed: isTitleReverted })}
          stroke="transparent"
          id={`groupTextPath_${groupIndex}`}
          data-testid="group-text-path"
        />
      </defs>
    </>
  )
}
