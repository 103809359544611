import { WppButton, WppGrid, WppRichtextHtml, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular3/Circular3Process.module.scss'
import { Circular3Svg } from 'pages/processBuilder/templates/circular3/Circular3Svg'
import { ProcessFormConfig } from 'types/process/process'

export const Circular3Process = ({ config }: { config: ProcessFormConfig }) => {
  const [activePhase, setActivePhase] = useState<MayBeNull<number>>(null)

  const handlePhaseEnter = useCallback(
    (phase: number) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      className={styles.root}
      data-testid="circular-three-process-container"
    >
      <WppGrid container>
        <Link
          to={config?.button?.url || ''}
          className={styles.projectLink}
          style={{ visibility: config?.button?.isVisible ? 'visible' : 'hidden' }}
          data-testid="circular-process-btn"
        >
          <WppButton
            className={styles.projectButton}
            style={{ background: config?.button?.colors?.[0], color: config?.button?.colors?.[1] }}
          >
            {config?.button?.title}
          </WppButton>
        </Link>
      </WppGrid>

      <Circular3Svg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        activePhase={activePhase}
        config={config}
      />

      {typeof activePhase === 'number' && (
        <Flex
          className={styles.phaseInfo}
          direction="column"
          gap={5}
          style={{
            backgroundColor: config.phases?.[activePhase]?.colors?.[8] || 'transparent',
            borderRadius: 'var(--wpp-border-radius-m)',
            padding: 'var(--wpp-card-padding-m, 12px 16px 16px 16px)',
          }}
          data-testid="circular-process-phase-description"
        >
          <WppTypography
            type="2xl-heading"
            className={styles.descriptionTitle}
            style={{
              color: config.phases?.[activePhase]?.colors?.[4] || '#001023',
              borderColor: config.phases?.[activePhase]?.colors?.[4] || '#001023',
            }}
          >
            {config.phases?.[activePhase]?.title}
          </WppTypography>

          <WppTypography
            type="l-body"
            className={styles.description}
            style={{ color: config.phases?.[activePhase]?.colors?.[4] || '#001023' }}
          >
            {config.phases?.[activePhase]?.description && (
              <div>
                <WppRichtextHtml value={config.phases?.[activePhase]?.description || ''} />
              </div>
            )}
          </WppTypography>
        </Flex>
      )}
    </Flex>
  )
}

export default Circular3Process
