import { WppRichtextHtml, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular3/phase/Phase.module.scss'
import { FormPhase as PhaseConfig } from 'types/process/process'

export const PhaseZero = ({
  onMouseEnter,
  onMouseLeave,
  isActive,
  activePhase,
  phase,
}: {
  onMouseEnter: MouseEventHandler
  onMouseLeave: MouseEventHandler
  isActive: boolean
  activePhase: MayBeNull<number>
  phase: PhaseConfig
}) => {
  const navigate = useNavigate()
  const url = phase.innerPageEnabled && phase?.id ? `./phase/${phase?.id}` : phase?.url || ''
  const isCustomUrl = phase?.url && !phase.innerPageEnabled

  const handleClick = useCallback(() => {
    isCustomUrl ? (window.location.href = url) : navigate(url)
  }, [navigate, isCustomUrl, url])

  return (
    <g
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      transform="translate(0, 0)"
      className={clsx({ [styles.phaseInteractive]: url })}
      onClick={handleClick}
      data-testid="circular-three-phase-zero-container"
    >
      <g>
        <foreignObject
          transform={`translate(0 ${isActive ? '85' : '150'})`}
          height="200"
          width="500"
          x="395"
          y="-140"
          dominantBaseline="central"
          alignmentBaseline="central"
          textAnchor="middle"
          opacity={!Number.isInteger(activePhase) || isActive ? 1 : 0.5}
          className={styles.foreignObject}
          data-testid="info-container"
        >
          <Flex className={styles.pointTextWrap} justify="center" align="start">
            <Flex direction="column" align="center">
              <div
                className={styles.phaseTitleWrap}
                style={{
                  order: 0,
                }}
              >
                <WppTypography
                  tag="h3"
                  type="s-body"
                  className={styles.zeroPhaseTitle}
                  style={{
                    color: phase?.colors?.[1] || '#fff',
                  }}
                  data-testid="phase-title"
                >
                  {phase.title}
                </WppTypography>
              </div>

              <WppTypography
                className={styles.phaseDescription}
                style={{
                  color: phase?.colors?.[2] || '#fff',
                  opacity: isActive ? '1' : '0',
                }}
                data-testid="phase-description-wrapper"
              >
                {phase.description && (
                  <WppRichtextHtml
                    data-testid="phase-description"
                    className="richTextDescription"
                    value={phase.description}
                  />
                )}
              </WppTypography>
            </Flex>
          </Flex>
        </foreignObject>
      </g>

      <path
        d="M3.71167 11.5947C2.17245 8.92841 1.66165 8.08521 0.640266 6.33045C-1.26487 3.06358 1.37914 -0.0520879 4.66061 0.000660372C13.6394 0.148355 22.6182 0.155389 31.5969 0.21517C35.1537 0.239786 37.1059 3.49611 35.3384 6.48518C33.7846 9.11204 32.2018 11.7213 30.6371 14.3411C30.4597 14.6401 30.7892 14.0247 30.6371 14.3411C28.5944 17.7522 26.0952 22.1092 24.0525 25.5203C23.1904 26.9585 22.3791 28.4284 21.4591 29.8315C19.5468 32.7397 15.9067 32.6589 14.2479 29.9722C13.1866 28.2561 12.2594 26.4416 11.2525 24.6798C8.75702 20.3087 6.22891 15.9552 3.71167 11.5947Z"
        transform="translate(620, 50) scale(1.2, 1.2)"
        fill={phase?.colors?.[0] || 'white'}
        fillOpacity={!Number.isInteger(activePhase) || isActive ? 1 : 0.5}
        data-testid="phase-triangle"
      />

      <defs>
        <path
          d="M 135.357 482.302 C 135.357 482.302, 832.623 482.302, 832.623 482.302"
          stroke="transparent"
          id="phaseZeroTitlePath"
        />
      </defs>
    </g>
  )
}
