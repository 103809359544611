import { WppButton } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/procterAndGambleEU/ProcterAndGambleEUProcess.module.scss'
import { ProcterAndGambleEUSvg } from 'pages/customProcess/components/procterAndGambleEU/ProcterAndGambleEUSvg'
import { Phases } from 'pages/customProcess/components/procterAndGambleEU/types'
import { routesManager } from 'utils/routesManager'

export const ProcterAndGambleEUProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const navigate = useNavigate()

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointClick = useCallback(() => {
    navigate('/ece26713-b460-4f16-9250-07a84e9a0cfe/phase/843577c6-31ec-4e60-ba96-c9144291dce0?back-to-custom')
  }, [navigate])

  return (
    <Flex align="center" justify="center" className={styles.root} data-testid="pgWorkspace-process">
      <ProcterAndGambleEUSvg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
        className={styles.svg}
        pointClassName={styles.svgPoint}
      />

      <Link to={routesManager.systemUrls.orchestration.root.url} className={styles.projectBtn}>
        <WppButton>Go to project</WppButton>
      </Link>
    </Flex>
  )
}
