import { WppTypography, WppPopover, WppButton, WppRichtextHtml } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular2/phasePopup/PhasePopup.module.scss'
import { FormPhase } from 'types/process/process'

export const PhasePopup = ({
  target,
  onClose,
  phase,
  placement,
  placementModifier,
}: {
  target: any
  onClose: () => void
  phase: FormPhase
  placement: string
  placementModifier: string
}) => {
  const targetRect = target?.getBoundingClientRect()

  const getPosition = useCallback(
    (reference: { width: number; height: number; x: number; y: number }): [number, number] => {
      switch (placement) {
        case 'left-top':
          return [targetRect.x - reference.x + targetRect.width, targetRect.y - reference.y]
        case 'left-bottom':
          return [targetRect.x - reference.x + targetRect.width, targetRect.y - reference.y - reference.height]
        case 'right-top':
          return [targetRect.x - reference.x - reference.width, targetRect.y - reference.y]
        case 'right-bottom':
          return [targetRect.x - reference.x - reference.width, targetRect.y - reference.y - reference.height]
        default:
          return [targetRect.x - reference.x, targetRect.y - reference.y]
      }
    },
    [targetRect, placement],
  )

  return (
    <WppPopover
      data-testid="group-phase-popup"
      config={{
        triggerTarget: target,
        offset: ({ reference, popper }) => getPosition({ ...reference, width: popper.width, height: popper.height }),
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
        onHide: onClose,
        trigger: 'mouseenter',
        appendTo: () => document.querySelector('#phasePopupRoot')!,
        interactive: false,
      }}
      externalClass={clsx(styles.pointPopup, {
        [styles.leftTop]: placement === 'left-top',
        [styles.leftBottom]: placement === 'left-bottom',
        [styles.rightTop]: placement === 'right-top',
        [styles.rightBottom]: placement === 'right-bottom',
        [styles.short]: placementModifier === 'short',
        [styles.long]: placementModifier === 'long',
      })}
    >
      <WppButton slot="trigger-element" hidden />

      <Flex
        style={{
          backgroundColor: phase?.colors?.[3] || 'transparent',
          borderRadius: 'var(--wpp-border-radius-m)',
          padding: 'var(--wpp-card-padding-m, 12px 16px 16px 16px)',
        }}
        gap={10}
        direction="column"
        align={placement === 'left-top' || placement === 'left-bottom' ? 'end' : 'start'}
        data-testid="phase-popup-body"
      >
        <hr className={styles.line} style={{ background: phase?.colors?.[0] || '#001023' }} />

        <WppTypography
          data-testid="phase-popup-title"
          type="xl-heading"
          className={styles.title}
          style={{ color: phase?.colors?.[2] || '#001023' }}
        >
          {phase.title}
        </WppTypography>

        <WppTypography
          data-testid="phase-popup-description"
          type="m-body"
          style={{ color: phase?.colors?.[2] || '#001023' }}
        >
          {phase.description && <WppRichtextHtml className="richTextDescription" value={phase.description} />}
        </WppTypography>

        {phase.overlayDescriptions.map(({ label }, index) => (
          <WppTypography
            data-testid="phase-popup-overlayDescription"
            type="s-strong"
            key={index}
            style={{ color: phase?.colors?.[2] || '#001023' }}
          >
            {label}
          </WppTypography>
        ))}
      </Flex>
    </WppPopover>
  )
}
