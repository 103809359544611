import { WppRichtextView } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/phaseBuilder/templates/simpleCard/processCard/descriptionCard/DescriptionCard.module.scss'
import { FormColumn } from 'types/phase/phase'
import { getPreviewImageUrl } from 'utils/builder'

interface Props {
  config: FormColumn
  iconBgColor?: string
  cardColor?: string
  width?: string
}

export const DescriptionCard = ({ config, iconBgColor, cardColor, width }: Props) => {
  return (
    <Flex direction="column" gap={16} className={styles.minWidth} style={{ width }} data-testid="description-card">
      <Flex justify="center" align="center" className={styles.iconContainer} style={{ background: iconBgColor }}>
        <img src={getPreviewImageUrl(config.icon)} alt="" className={styles.icon} data-testid="icon" />
      </Flex>
      {config.content && (
        <div className={styles.richTextContainer}>
          <WppRichtextView value={config.content} style={{ color: cardColor }} data-testid="rich-text-view" />
        </div>
      )}
    </Flex>
  )
}
