import { WppTypography, WppIconChevron, WppRichtextHtml } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/linear2/phase/Phase.module.scss'
import { FormPhase } from 'types/process/process'

export const Phase = ({
  isActive,
  isBlured,
  isEditMode,
  phase,
  size,
  phasesShift,
  zIndex,
  isSmall,
  onMouseLeave,
  onMouseEnter,
  withArrow,
}: {
  hoveredIndex: MayBeNull<number>
  isActive: boolean
  isBlured: boolean
  isEditMode: boolean
  phase: FormPhase
  size: number
  phasesShift: number
  zIndex: number
  isSmall: MayBeNull<boolean>
  onMouseLeave: MouseEventHandler
  onMouseEnter: MouseEventHandler
  withArrow: boolean
}) => {
  const navigate = useNavigate()
  const url = phase.innerPageEnabled && phase?.id ? `./phase/${phase?.id}` : phase?.url || ''

  const handlePhaseClick = () => {
    navigate(url)
  }

  return (
    <Flex
      className={clsx(styles.root, {
        [styles.small]: isSmall,
        [styles.blured]: isBlured,
        [styles.withArrow]: withArrow,
        [styles.cursorPointer]: !!url,
      })}
      justify="center"
      align="center"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        marginRight: `-${phasesShift}px`,
        background: !isActive ? phase.colors?.[0] || 'gray' : 'transparent',
        zIndex: isActive ? 999 : zIndex,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handlePhaseClick}
      data-testid="linear-two-phase"
    >
      <Flex direction="row">
        {!isActive && (
          <WppTypography
            type="m-midi"
            className={styles.title}
            style={{ color: phase.colors[2] }}
            data-testid="inactive-phase-title"
          >
            {phase.title}
          </WppTypography>
        )}
      </Flex>
      <Flex
        className={clsx(styles.phasePopup, isEditMode && styles.inEditMode, { [styles.isActive]: isActive })}
        justify="center"
        align="center"
        direction="column"
        gap={10}
        style={{
          background: phase.colors?.[1] || 'rgba(230, 230, 230, .7)',
          opacity: isActive ? 1 : 0,
        }}
        data-testid="active-phase-info"
      >
        <Flex direction="row" align="center">
          <WppTypography type="l-strong" className={styles.title} style={{ color: phase.colors[2] }}>
            {phase.title}
          </WppTypography>
          <WppIconChevron slot="icon-start" direction="right" color="white" />
        </Flex>
        {isActive && (
          <>
            <WppTypography type="s-body" style={{ color: phase.colors[3] }} data-testid="active-phase-description">
              {phase.description && <WppRichtextHtml className="richTextDescription" value={phase.description} />}
            </WppTypography>
            {phase.overlayDescriptions.length && (
              <WppTypography type="s-strong" style={{ color: phase.colors[4] }}>
                Tools:
              </WppTypography>
            )}
            <ul className={styles.list}>
              {phase.overlayDescriptions?.map((description, index) => {
                return (
                  <li key={description.id} style={{ color: phase.colors[index + 5] || 'white' }}>
                    <WppTypography
                      type="s-body"
                      style={{ color: phase.colors[index + 5] || 'white' }}
                      data-testid="active-phase-overlayDescription"
                    >
                      {description.label}
                    </WppTypography>
                  </li>
                )
              })}
            </ul>
          </>
        )}
      </Flex>
    </Flex>
  )
}
