import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import deliverLogoImgSrc from 'pages/customProcess/components/henkel/assets/deliver-logo.png'
import designLogoImgSrc from 'pages/customProcess/components/henkel/assets/design-logo.png'
import discoverLogoImgSrc from 'pages/customProcess/components/henkel/assets/discover-logo.png'
import henkelLogoImgSrc from 'pages/customProcess/components/henkel/assets/henkel-logo.png'
import activationPhaseImgSrc from 'pages/customProcess/components/henkel/assets/phaseDetails/activation.png'
import adaptPhaseImgSrc from 'pages/customProcess/components/henkel/assets/phaseDetails/adapt.png'
import ambitionPhaseImgSrc from 'pages/customProcess/components/henkel/assets/phaseDetails/ambition.png'
import approachPhaseImgSrc from 'pages/customProcess/components/henkel/assets/phaseDetails/approach.png'
import assetsPhaseImgSrc from 'pages/customProcess/components/henkel/assets/phaseDetails/assets.png'
import audiencePhaseImgSrc from 'pages/customProcess/components/henkel/assets/phaseDetails/audience.png'
import processSchemeBgImgSrc from 'pages/customProcess/components/henkel/assets/process-scheme-bg.png'
import styles from 'pages/customProcess/components/henkel/HenkelProcess.module.scss'
import { DeliverPhaseSvg } from 'pages/customProcess/components/henkel/processScheme/phasesSVGs/DeliverPhaseSvg'
import { DesignPhaseSvg } from 'pages/customProcess/components/henkel/processScheme/phasesSVGs/DesignPhaseSvg'
import { DiscoverPhaseSvg } from 'pages/customProcess/components/henkel/processScheme/phasesSVGs/DiscoverPhaseSvg'
import { ProcessScheme } from 'pages/customProcess/components/henkel/processScheme/ProcessScheme'
import { ProcessSchemeTitleLinks } from 'pages/customProcess/components/henkel/processScheme/ProcessSchemeTitleLinks'
import { Phases, PhaseDetails } from 'pages/customProcess/components/henkel/types'

export const HenkelProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const [activePhaseDetail, setActivePhaseDetail] = useState<MayBeNull<PhaseDetails>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePhaseDetailEnter = useCallback(
    (phase: PhaseDetails) => () => {
      setActivePhaseDetail(phase)
    },
    [],
  )

  const handlePhaseDetailLeave = useCallback(() => {
    setActivePhaseDetail(null)
  }, [])

  return (
    <div className={styles.root} data-testid="henkel-process">
      <Flex align="center" justify="center" className={styles.pageContent}>
        <div className={styles.scaleDown}>
          <div
            className={clsx(
              styles.schemeRedOverlay,
              styles.centered,
              (!!activePhase || !!activePhaseDetail) && styles.schemeRedOverlayActive,
            )}
          />
          <div
            className={clsx(
              styles.schemeBlurOverlay,
              styles.centered,
              (!!activePhase || !!activePhaseDetail) && styles.schemeBlurOverlayActive,
            )}
          />
          <img src={processSchemeBgImgSrc} alt="" className={clsx(styles.processSchemeBg, styles.centered)} />
          <div className={styles.processSchemeWrapper}>
            <ProcessScheme activePhase={activePhase} />
            <div className={clsx(styles.discoverPhase, activePhase === Phases.Discover && styles.fullOpacity)}>
              <DiscoverPhaseSvg onPhaseEnter={handlePhaseEnter} onPhaseLeave={handlePhaseLeave} />
            </div>
            <div className={clsx(styles.designPhase, activePhase === Phases.Design && styles.fullOpacity)}>
              <DesignPhaseSvg onPhaseEnter={handlePhaseEnter} onPhaseLeave={handlePhaseLeave} />
            </div>
            <div className={clsx(styles.deliverPhase, activePhase === Phases.Deliver && styles.fullOpacity)}>
              <DeliverPhaseSvg onPhaseEnter={handlePhaseEnter} onPhaseLeave={handlePhaseLeave} />
            </div>
            <img
              src={adaptPhaseImgSrc}
              alt=""
              className={clsx(styles.adaptPhase, activePhaseDetail === PhaseDetails.Adapt && styles.fullOpacity)}
            />
            <img
              src={ambitionPhaseImgSrc}
              alt=""
              className={clsx(styles.ambitionPhase, activePhaseDetail === PhaseDetails.Ambition && styles.fullOpacity)}
            />
            <img
              src={audiencePhaseImgSrc}
              alt=""
              className={clsx(styles.audiencePhase, activePhaseDetail === PhaseDetails.Audience && styles.fullOpacity)}
            />
            <img
              src={approachPhaseImgSrc}
              alt=""
              className={clsx(styles.approachPhase, activePhaseDetail === PhaseDetails.Approach && styles.fullOpacity)}
            />
            <img
              src={assetsPhaseImgSrc}
              alt=""
              className={clsx(styles.assetsPhase, activePhaseDetail === PhaseDetails.Assets && styles.fullOpacity)}
            />
            <img
              src={activationPhaseImgSrc}
              alt=""
              className={clsx(
                styles.activationPhase,
                activePhaseDetail === PhaseDetails.Activation && styles.fullOpacity,
              )}
            />
          </div>
          <img src={henkelLogoImgSrc} alt="Henkel logo" className={clsx(styles.henkelLogo, styles.centered)} />
          {(activePhaseDetail === PhaseDetails.Adapt || activePhaseDetail === PhaseDetails.Ambition) && (
            <img src={discoverLogoImgSrc} alt="" className={clsx(styles.henkelLogo, styles.centered)} />
          )}
          {(activePhaseDetail === PhaseDetails.Audience || activePhaseDetail === PhaseDetails.Approach) && (
            <img src={designLogoImgSrc} alt="" className={clsx(styles.henkelLogo, styles.centered)} />
          )}
          {(activePhaseDetail === PhaseDetails.Assets || activePhaseDetail === PhaseDetails.Activation) && (
            <img src={deliverLogoImgSrc} alt="" className={clsx(styles.henkelLogo, styles.centered)} />
          )}
          <div className={styles.processSchemeTitleLinks}>
            <ProcessSchemeTitleLinks
              activePhase={activePhase}
              onPhaseDetailEnter={handlePhaseDetailEnter}
              onPhaseDetailLeave={handlePhaseDetailLeave}
            />
          </div>
        </div>
      </Flex>
    </div>
  )
}
