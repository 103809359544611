import { WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import cycleDirectionImgUrl from 'pages/customProcess/components/warnerBros/assets/cycle-direction.png'
import styles from 'pages/customProcess/components/warnerBros/WarnerBrosProcess.module.scss'
import { routesManager } from 'utils/routesManager'

enum ProcessPhases {
  Marketing = 'Marketing',
  Campaign = 'Campaign',
  Optimisation = 'Optimisation',
}

export const WarnerBrosProcess = () => {
  const [activePhase, setActivePhase] = useState<ProcessPhases | null>(null)

  const activatePhase = useCallback((phase: ProcessPhases) => {
    setActivePhase(phase)
  }, [])

  const deactivatePhase = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <div className={styles.root} data-testid="warnerBrothers-process">
      <Flex direction="column" className={styles.pageContent}>
        <div>
          <Flex justify="end">
            <Link to={routesManager.systemUrls.orchestration.root.url}>
              <WppButton className={styles.projectsButton}>Go to project details</WppButton>
            </Link>
          </Flex>
          <Flex gap={32} align="end">
            <div>
              <h1 className={clsx(styles.titleTypography, styles.titlePart1)}>The way</h1>
              <h1 className={clsx(styles.titleTypography, styles.titlePart2)}>we work</h1>
            </div>
            <p className={styles.description}>
              {
                'We keep our WBD relationship at the center\nof an integrated approach ensuring a smooth\nand effective management of the workload'
              }
            </p>
          </Flex>
        </div>
        <Flex justify="center" align="center" className={styles.schemeContainer}>
          <Flex direction="column" gap={39}>
            <div className={styles.schemeWrapper}>
              <div
                className={clsx(
                  styles.marketingBubble,
                  activePhase === ProcessPhases.Marketing && styles.marketingBubbleActive,
                  activePhase !== null && activePhase !== ProcessPhases.Marketing && styles.obscuredBubble,
                )}
                onMouseEnter={() => activatePhase(ProcessPhases.Marketing)}
                onMouseLeave={deactivatePhase}
              >
                <div
                  className={clsx(
                    styles.activePhaseContent,
                    activePhase === ProcessPhases.Marketing && styles.fullOpacity,
                  )}
                >
                  <div className={styles.activeBubbleName}>Marketing</div>
                  <Flex gap={46}>
                    <div className={styles.activeBubbleTitle}>{'Identify the growth\nplan to Breakthrough'}</div>
                    <div className={styles.activeBubbleDescription}>
                      {
                        'The Marketing Cycle identifies longer term\nBreakthrough growth, determining the role\nmarketing plays in delivering against the\nwider ambitions of your client′s business'
                      }
                    </div>
                    <div>
                      <div className={styles.heroToolsTitle}>Hero Tools:</div>
                      <ul className={styles.heroToolsList}>
                        <li>Influencer Power Index</li>
                        <li>Culture Club</li>
                        <li>WPP BAV</li>
                        <li>Audience Origin</li>
                      </ul>
                    </div>
                  </Flex>
                </div>
                <Flex
                  justify="center"
                  align="center"
                  className={clsx(
                    styles.inactivePhaseContent,
                    activePhase === ProcessPhases.Marketing && styles.zeroOpacity,
                  )}
                >
                  <div className={styles.bubbleTitle}>Marketing</div>
                </Flex>
              </div>
              <div
                className={clsx(
                  styles.campaignBubble,
                  activePhase === ProcessPhases.Campaign && styles.campaignBubbleActive,
                  activePhase !== null && activePhase !== ProcessPhases.Campaign && styles.obscuredBubble,
                )}
                onMouseEnter={() => activatePhase(ProcessPhases.Campaign)}
                onMouseLeave={deactivatePhase}
              >
                <div
                  className={clsx(
                    styles.activePhaseContent,
                    activePhase === ProcessPhases.Campaign && styles.fullOpacity,
                  )}
                >
                  <div className={styles.activeBubbleName}>Campaign</div>
                  <Flex gap={46}>
                    <div className={styles.activeBubbleTitle}>{'Design Breakthrough\nsolutions'}</div>
                    <div className={styles.activeBubbleDescription}>
                      {
                        'The Campaign Cycle designs the\nBreakthrough communications decisions\nand ideas that will bring our plans to life'
                      }
                    </div>
                    <div>
                      <div className={styles.heroToolsTitle}>Hero Tools:</div>
                      <ul className={styles.heroToolsList}>
                        <li>emRapidModelling</li>
                        <li>Planning Playbook</li>
                        <li>emPlanner</li>
                        <li>Audience Translator</li>
                        <li>Audience Origin</li>
                      </ul>
                    </div>
                  </Flex>
                </div>
                <Flex
                  justify="center"
                  align="center"
                  className={clsx(
                    styles.inactivePhaseContent,
                    activePhase === ProcessPhases.Campaign && styles.zeroOpacity,
                  )}
                >
                  <div className={styles.bubbleTitle}>Campaign</div>
                </Flex>
              </div>
              <div
                className={clsx(
                  styles.optimisationBubble,
                  activePhase === ProcessPhases.Optimisation && styles.optimisationBubbleActive,
                  activePhase !== null && activePhase !== ProcessPhases.Optimisation && styles.obscuredBubble,
                )}
                onMouseEnter={() => activatePhase(ProcessPhases.Optimisation)}
                onMouseLeave={deactivatePhase}
              >
                <div
                  className={clsx(
                    styles.activePhaseContent,
                    activePhase === ProcessPhases.Optimisation && styles.fullOpacity,
                  )}
                >
                  <div className={styles.activeBubbleName}>Optimisation</div>
                  <Flex gap={46}>
                    <div className={styles.activeBubbleTitle}>{'Deliver, analyse and\nadapt to Breakthrough'}</div>
                    <div className={styles.activeBubbleDescription}>
                      {
                        'The Optimisation Cycle is where we test, learn\nand optimise activity inflight, ensuring that our\ndecisions are Breakthrough made towards the\nright outcome for our brand and our audience'
                      }
                    </div>
                    <div>
                      <div className={styles.heroToolsTitle}>Hero Tools:</div>
                      <ul className={styles.heroToolsList}>
                        <li>emScreen</li>
                        <li>Contact Optimiser</li>
                      </ul>
                    </div>
                  </Flex>
                </div>
                <Flex
                  justify="center"
                  align="center"
                  className={clsx(
                    styles.inactivePhaseContent,
                    activePhase === ProcessPhases.Optimisation && styles.zeroOpacity,
                  )}
                >
                  <div className={styles.bubbleTitle}>Optimisation</div>
                </Flex>
              </div>
            </div>
            <img alt="" src={cycleDirectionImgUrl} className={styles.cycleDirectionImg} draggable="false" />
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
