import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as Logo } from 'pages/customProcess/components/ibmNew/assets/logo.svg'
import phase1DecoImgSrc from 'pages/customProcess/components/ibmNew/assets/phase1-deco.svg'
import phase2DecoImgSrc from 'pages/customProcess/components/ibmNew/assets/phase2-deco.svg'
import phase3DecoImgSrc from 'pages/customProcess/components/ibmNew/assets/phase3-deco.svg'
import phase4DecoImgSrc from 'pages/customProcess/components/ibmNew/assets/phase4-deco.svg'
import phase5DecoImgSrc from 'pages/customProcess/components/ibmNew/assets/phase5-deco.svg'
import { ReactComponent as AIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/a.svg'
import { ReactComponent as CIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/c.svg'
import { ReactComponent as IIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/i.svg'
import { ReactComponent as RIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/r.svg'
import magicLogoUrl from 'pages/customProcess/components/ibmNew/assets/toolLogos/magic.png'
import synapseLogoUrl from 'pages/customProcess/components/ibmNew/assets/toolLogos/synapse.png'
import { InputOutputCard } from 'pages/customProcess/components/ibmNew/components/inputOutputCard/InputOutputCard'
import { Phase } from 'pages/customProcess/components/ibmNew/components/phase/Phase'
import { PhaseConnectionArrows } from 'pages/customProcess/components/ibmNew/components/phaseConnectionArrows/PhaseConnectionArrows'
import { PhaseDivider } from 'pages/customProcess/components/ibmNew/components/phaseDivider/PhaseDivider'
import { ResponsibilitiesInfo } from 'pages/customProcess/components/ibmNew/components/responsibilitiesInfo/ResponsibilitiesInfo'
import { phaseLinks } from 'pages/customProcess/components/ibmNew/ibmConfig'
import styles from 'pages/customProcess/components/ibmNew/IbmProcess.module.scss'
import { Phases } from 'pages/customProcess/components/ibmNew/types'

export const IbmProcess = () => {
  const [hoveredPhase, setHoveredPhase] = useState<MayBeNull<Phases>>(null)
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setHoveredPhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setHoveredPhase(null)
    setActivePhase(null)
  }, [])

  const handlePhaseOpen = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  return (
    <div className={styles.root} data-testid="ibm-process">
      <ResponsibilitiesInfo isVisible={!!activePhase} />
      <Flex gap={activePhase ? 0 : 8} className={styles.processWrapper}>
        <div className={clsx(styles.inputOutputBox, styles.inputBox, activePhase && styles.inputOutputBoxHidden)}>
          <InputOutputCard
            arrowPosition="right"
            title="Stage input"
            content="IBM has shared the Media Brief by business objective, with GEO nuances included"
          />
        </div>
        <div className={styles.process}>
          <Flex justify="between" align="end" className={styles.header}>
            <WppTypography tag="h1" type="4xl-display" className={clsx(styles.title, activePhase && styles.faded)}>
              IBM Planning Process
            </WppTypography>
            <Logo className={clsx(styles.logo, activePhase && styles.faded)} />
          </Flex>
          <div className={styles.arrowsWrapper}>
            <PhaseConnectionArrows activePhase={activePhase} />
          </div>
          <Flex className={styles.phasesWrapper}>
            <Phase
              className={clsx(styles.phase1, activePhase === Phases.CentralStrategy && styles.phase1Open)}
              phase={Phases.CentralStrategy}
              isHovered={hoveredPhase === Phases.CentralStrategy}
              isOpen={activePhase === Phases.CentralStrategy}
              title={'01\nPlanning:\nCentral Strategy'}
              img={phase1DecoImgSrc}
              description="A unified Central Approach to paid media, defining Strategic pillars, Audiences and KPIs to create a central media mix."
              teams={[
                {
                  name: 'Centralized',
                  responsibilities: [<RIcon />, <AIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'WW lead & delivered\n'}</span>
                        to GEOs to implement
                      </WppTypography>
                    </div>
                  ),
                },
                {
                  name: 'GEOs',
                  responsibilities: [<CIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'Regions '}</span>
                        take the
                        <span className={styles.tooltipTextBold}>{' WW\n'}</span>
                        {'direction and adjust for\nnuances / based on their POV'}
                      </WppTypography>
                    </div>
                  ),
                },
              ]}
              tools={[{ name: 'Synapse', logoUrl: synapseLogoUrl }, { name: 'Media Budget Tool' }]}
              stageInput={
                <div className={styles.inputPosition1}>
                  <InputOutputCard
                    contentStyles={styles.stageInputCard1}
                    title="Stage input"
                    content="IBM has shared the Media Brief by business objective, with GEO nuances included"
                    arrowPosition="bottom"
                  />
                </div>
              }
              stageOutput={
                <InputOutputCard
                  contentStyles={styles.stageOutputCard1}
                  title="Stage output"
                  content="Annual Strategy is sent to IBM for final approval and GEO distribution"
                />
              }
              link={phaseLinks[Phases.CentralStrategy]}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseOpen}
            />
            <PhaseDivider />
            <Phase
              className={clsx(styles.phase2, activePhase === Phases.ChannelActivity && styles.phase2Open)}
              phase={Phases.ChannelActivity}
              isHovered={hoveredPhase === Phases.ChannelActivity}
              isOpen={activePhase === Phases.ChannelActivity}
              title={'02\nPlanning:\nChannel Activity'}
              img={phase2DecoImgSrc}
              description="Transform the annual strategy into an actionable Geo specific plan with translated audiences, specific channels, inventory and tactics."
              teams={[
                {
                  name: 'Centralized',
                  responsibilities: [<RIcon />, <AIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'WW lead & delivered\n'}</span>
                        to GEOs to implement
                      </WppTypography>
                    </div>
                  ),
                },
                {
                  name: 'GEOs',
                  responsibilities: [<RIcon />, <CIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'Regions '}</span>
                        take the
                        <span className={styles.tooltipTextBold}>{' WW\n'}</span>
                        {'direction and adjust for\nnuances / based on their POV'}
                      </WppTypography>
                    </div>
                  ),
                },
              ]}
              tools={[
                { name: 'Ruby' },
                { name: 'Amal' },
                { name: 'Magic!', logoUrl: magicLogoUrl },
                { name: 'Synapse', logoUrl: synapseLogoUrl },
                { name: 'Saphire' },
              ]}
              stageInput={
                <div className={styles.inputPosition2}>
                  <InputOutputCard
                    contentStyles={styles.stageInputCard2}
                    title="Stage input"
                    content="Annual Strategy"
                    arrowPosition="bottom"
                  />
                </div>
              }
              stageOutput={
                <InputOutputCard
                  contentStyles={styles.stageOutputCard2}
                  title="Stage output"
                  content="Campaign is ready to go live"
                />
              }
              link={phaseLinks[Phases.ChannelActivity]}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseOpen}
            />
            <PhaseDivider />
            <Phase
              className={clsx(styles.phase3, activePhase === Phases.Execution && styles.phase3Open)}
              phase={Phases.Execution}
              isHovered={hoveredPhase === Phases.Execution}
              isOpen={activePhase === Phases.Execution}
              title={'03\nExecutional Details'}
              img={phase3DecoImgSrc}
              description="Ensure necessary elements and administration tasks are done to execute paid media activity. Ready to go!"
              teams={[
                {
                  name: 'GEOs',
                  responsibilities: [<RIcon />, <AIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'Regions '}</span>
                        take the
                        <span className={styles.tooltipTextBold}>{' WW\n'}</span>
                        {'direction and adjust for\nnuances / based on their POV'}
                      </WppTypography>
                    </div>
                  ),
                },
                {
                  name: 'Centralized',
                  responsibilities: [<RIcon />, <IIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'WW lead & delivered\n'}</span>
                        to GEOs to implement
                      </WppTypography>
                    </div>
                  ),
                },
              ]}
              tools={[{ name: 'Media Budget Tool' }, { name: 'Media Tool' }, { name: 'Magic!', logoUrl: magicLogoUrl }]}
              stageInput={
                <div className={styles.inputPosition3}>
                  <InputOutputCard
                    contentStyles={styles.stageInputCard3}
                    title="Stage input"
                    content="Campaign is ready to go-live!"
                    arrowPosition="bottom"
                  />
                </div>
              }
              stageOutput={
                <InputOutputCard
                  contentStyles={styles.stageOutputCard3}
                  title="Stage output"
                  content="Campaign IS Live!"
                />
              }
              link={phaseLinks[Phases.Execution]}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseOpen}
            />
            <PhaseDivider showBackArrow />
            <Phase
              className={clsx(styles.phase4, activePhase === Phases.Performance && styles.phase4Open)}
              phase={Phases.Performance}
              isHovered={hoveredPhase === Phases.Performance}
              isOpen={activePhase === Phases.Performance}
              title={'04\nPerformance'}
              img={phase4DecoImgSrc}
              description="Report & Analyse performance data."
              teams={[
                {
                  name: 'GEOs',
                  responsibilities: [<RIcon />, <AIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'Regions '}</span>
                        take the
                        <span className={styles.tooltipTextBold}>{' WW\n'}</span>
                        {'direction and adjust for\nnuances / based on their POV'}
                      </WppTypography>
                    </div>
                  ),
                },
                {
                  name: 'Centralized',
                  responsibilities: [<CIcon />, <IIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'WW lead & delivered\n'}</span>
                        to GEOs to implement
                      </WppTypography>
                    </div>
                  ),
                },
              ]}
              tools={[{ name: 'EPM Dashboards' }, { name: 'Media Tool' }]}
              stageInput={
                <div className={styles.inputPosition4}>
                  <InputOutputCard
                    contentStyles={styles.stageInputCard4}
                    title="Stage input"
                    content="Channel KPI Blueprint, Investment Plan and Measurement Framework"
                    arrowPosition="bottom"
                  />
                </div>
              }
              stageOutput={
                <InputOutputCard
                  contentStyles={styles.stageOutputCard4}
                  title="Stage output"
                  content="Performance is tracked and optimised"
                />
              }
              link={phaseLinks[Phases.Performance]}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseOpen}
            />
            <PhaseDivider />
            <Phase
              className={clsx(styles.phase5, activePhase === Phases.Reconciliation && styles.phase5Open)}
              phase={Phases.Reconciliation}
              isHovered={hoveredPhase === Phases.Reconciliation}
              isOpen={activePhase === Phases.Reconciliation}
              title={'05\nReconciliation'}
              img={phase5DecoImgSrc}
              description="Reconcile planned vs actual"
              teams={[
                {
                  name: 'GEOs',
                  responsibilities: [<RIcon />, <AIcon />],
                  tooltipContent: (
                    <div>
                      <WppTypography type="xs-body" className={styles.tooltipText}>
                        <span className={styles.tooltipTextBold}>{'Regions '}</span>
                        take the
                        <span className={styles.tooltipTextBold}>{' WW\n'}</span>
                        {'direction and adjust for\nnuances / based on their POV'}
                      </WppTypography>
                    </div>
                  ),
                },
              ]}
              tools={[{ name: 'Media Tool' }, { name: 'Prisma' }]}
              stageInput={
                <div className={styles.inputPosition5}>
                  <InputOutputCard
                    contentStyles={styles.stageInputCard5}
                    title="Stage input"
                    content="Performance is tracked and optimised"
                    arrowPosition="bottom"
                  />
                </div>
              }
              stageOutput={
                <InputOutputCard
                  contentStyles={styles.stageOutputCard5}
                  title="Stage output"
                  content="Financials are adjusted, invoices are sent"
                />
              }
              link={phaseLinks[Phases.Reconciliation]}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseOpen}
            />
          </Flex>
        </div>
        <div className={clsx(styles.inputOutputBox, styles.outputBox, activePhase && styles.inputOutputBoxHidden)}>
          <InputOutputCard
            arrowPosition="left"
            title="Stage output"
            content="Financials are adjusted, invoices are sent"
          />
        </div>
      </Flex>
    </div>
  )
}
