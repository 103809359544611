import { MayBeNull } from '@wpp-open/core'
import { MouseEventHandler, useMemo, useCallback } from 'react'

import styles from 'pages/processBuilder/templates/circular2/group/Group.module.scss'
import { PhasePoint } from 'pages/processBuilder/templates/circular2/phasePoint/PhasePoint'
import { getPieItemPath } from 'pages/processBuilder/utils'
import { FormPhase, FormGroup } from 'types/process/process'

export const Group = ({
  group,
  phases,
  isActive,
  activePhase,
  activeGroup,
  rotation,
  size,
  onGroupEnter,
  onGroupLeave,
  onPhaseEnter,
  onPhaseLeave,
}: {
  group: FormGroup
  phases: FormPhase[]
  isActive: boolean
  activePhase: MayBeNull<number>
  activeGroup: MayBeNull<number>
  rotation: number
  size: number
  onGroupEnter: MouseEventHandler
  onGroupLeave: MouseEventHandler
  onPhaseEnter: (point: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
}) => {
  const groupPhases = useMemo(
    () =>
      group?.phasesLocalIds?.reduce((accum: FormPhase[], groupPhaseId: string) => {
        const phase = phases.find(({ localId }) => localId === groupPhaseId)
        phase && accum.push(phase)

        return accum
      }, []),
    [group.phasesLocalIds, phases],
  )

  const isTitleReverted = rotation > 90 && rotation < 270

  const pathData = getPieItemPath({
    radius: 323,
    size,
    x: 640,
    y: 455,
  })

  const isActivePhase = useCallback((index: number) => index === activePhase, [activePhase])

  return (
    <g
      onMouseOver={onGroupEnter}
      onMouseLeave={onGroupLeave}
      transform={`rotate(${rotation} 640 455)`}
      data-testid="circular-two-group"
    >
      <g className={styles.groupOverlay} opacity={isActive ? 1 : 0} data-testid="group-overlay">
        <path d={pathData} fill="url(#widgetPartBackgroundImage)" />
        <path d={pathData} fill={group.colors?.[2] || 'rgba(255,0,0,0.6)'} stroke={group?.colors?.[3]} />
      </g>

      <path
        d="M 422.084 895.965 C 403.31 861.675 392.635 822.318 392.635 780.468 C 392.635 647.458 500.461 539.632 633.471 539.632"
        stroke="transparent"
        id="groupTextPath"
        transform="translate(285, -185) rotate(60 640 455)"
      />

      <path
        d="M 633.471 539.632 C 500.461 539.632 392.635 647.458 392.635 780.468 C 392.635 822.318 403.31 861.675 422.084 895.965"
        stroke="transparent"
        id="groupTextPath_inverted"
        transform="translate(285, -185) rotate(60 640 455)"
      />

      <text alignmentBaseline="middle" className={styles.title}>
        <textPath
          xlinkHref={isTitleReverted ? '#groupTextPath_inverted' : '#groupTextPath'}
          fill={(isActive ? group?.colors?.[5] || group?.colors?.[0] : group?.colors?.[0]) || '#001023'}
          fillOpacity={!Number.isInteger(activeGroup) || isActive ? 1 : 0.5}
          startOffset="50%"
          textAnchor="middle"
          alignmentBaseline="central"
          data-testid="group-title"
        >
          {group?.title}
        </textPath>
      </text>

      {groupPhases?.map((phase, index) => (
        <PhasePoint
          key={index}
          phase={phase}
          phasesCount={groupPhases.length}
          groupSize={size}
          index={index}
          activePhase={activePhase}
          isActive={isActivePhase(index)}
          isGroupActive={isActive}
          groupRotation={rotation}
          onPhaseEnter={onPhaseEnter(index)}
          onPhaseLeave={onPhaseLeave}
        />
      ))}
    </g>
  )
}
