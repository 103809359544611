import { MayBeNull } from '@wpp-open/core'
import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ProcessSvg } from 'pages/customProcess/components/yumChina/processSvg/ProcessSvg'
import { Phases } from 'pages/customProcess/components/yumChina/types'
import { links } from 'pages/customProcess/components/yumChina/yumChinaConfig'
import styles from 'pages/customProcess/components/yumChina/YUMChinaProcess.module.scss'

export const YUMChinaProcess = () => {
  const navigate = useNavigate()

  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePhaseClick = useCallback(
    (phase: Phases) => () => {
      navigate(links[phase])
    },
    [navigate],
  )

  return (
    <div className={styles.root} data-testid="yumChina-process">
      <Flex justify="center" align="center" className={styles.svgContainer}>
        <ProcessSvg
          activePhase={activePhase}
          onPhaseEnter={handlePhaseEnter}
          onPhaseLeave={handlePhaseLeave}
          onPhaseClick={handlePhaseClick}
        />
      </Flex>
    </div>
  )
}
