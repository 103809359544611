// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dzvFa{font-weight:600;font-size:26px;font-family:var(--wpp-font-family, sans-serif);letter-spacing:1px;text-transform:uppercase}.isxyc{transition:.2s ease}`, "",{"version":3,"sources":["webpack://./src/pages/processBuilder/templates/circular2/group/Group.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,cAAA,CACA,8CAAA,CACA,kBAAA,CACA,wBAAA,CAGF,OACE,mBAAA","sourcesContent":[".title {\n  font-weight: 600;\n  font-size: 26px;\n  font-family: var(--wpp-font-family, sans-serif);\n  letter-spacing: 1px;\n  text-transform: uppercase;\n}\n\n.groupOverlay {\n  transition: 0.2s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `dzvFa`,
	"groupOverlay": `isxyc`
};
export default ___CSS_LOADER_EXPORT___;
