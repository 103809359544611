import { WppTypography, WppTooltip, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import centralIllustrationSrc from 'pages/customProcess/components/nestleHealth/assets/central-illustration.svg'
import decorativeCircleImgSrc from 'pages/customProcess/components/nestleHealth/assets/decorative-circle.png'
import { ProcessSchemePhase } from 'pages/customProcess/components/nestleHealth/constants'
import styles from 'pages/customProcess/components/nestleHealth/NestleHealthProcess.module.scss'
// import hintArrow1_1Src from 'pages/customProcess/components/nestleHealth/processScheme/assets/hint-arrow1-1.svg'
// import hintArrow1_2Src from 'pages/customProcess/components/nestleHealth/processScheme/assets/hint-arrow1-2.svg'
import hintArrow1Src from 'pages/customProcess/components/nestleHealth/processScheme/assets/hint-arrow1.svg'
// import hintArrow2Src from 'pages/customProcess/components/nestleHealth/processScheme/assets/hint-arrow2.svg'
// import hintArrow3Src from 'pages/customProcess/components/nestleHealth/processScheme/assets/hint-arrow3.svg'
import { ProcessPhaseInfo } from 'pages/customProcess/components/nestleHealth/processScheme/processPhaseInfo/ProcessPhaseInfo'
import { ProcessScheme } from 'pages/customProcess/components/nestleHealth/processScheme/ProcessScheme'

const mroiTooltipText = 'MROI:\n•  Virtuous Insights\n•  Budget Allocation and Simulation\n• Strategic Learnings'

export const NestleHealthProcess = () => {
  const navigate = useNavigate()
  const processDetailsLinkRef = useRef(null)

  const [selectedPhase, setSelectedPhase] = useState<ProcessSchemePhase>(ProcessSchemePhase.EMPTY)

  const decorativeCircleRotatione = useMemo(() => {
    switch (selectedPhase) {
      case ProcessSchemePhase.SYNAPSE:
        return styles.decorativeCircleRotation1
      case ProcessSchemePhase.SYNAPSE_2:
        return styles.decorativeCircleRotation2
      case ProcessSchemePhase.BAV_AUDIENCE_DB:
        return styles.decorativeCircleRotation3
      case ProcessSchemePhase.SYNAPSE_3:
        return styles.decorativeCircleRotation4
      case ProcessSchemePhase.CHOREOGRAPH_SYNAPSE:
        return styles.decorativeCircleRotation5
      case ProcessSchemePhase.MEDIA_ACTIVITY_PLAN:
        return styles.decorativeCircleRotation6
      case ProcessSchemePhase.NEXUS:
        return styles.decorativeCircleRotation7
      case ProcessSchemePhase.ADVERITY:
        return styles.decorativeCircleRotation8
    }
  }, [selectedPhase])

  const getProcessDetailsPath = () => {
    switch (selectedPhase) {
      case ProcessSchemePhase.WHEN_NECESSARY:
        return 'nestle-health/big-idea'
      case ProcessSchemePhase.SYNAPSE:
        return 'nestle-health/portfolio-allocation'
      case ProcessSchemePhase.SYNAPSE_2:
        return 'nestle-health/campaign-brief'
      case ProcessSchemePhase.BAV_AUDIENCE_DB:
        return 'nestle-health/campaign-strategy'
      case ProcessSchemePhase.SYNAPSE_3:
        return 'nestle-health/campaign-budget-settings'
      case ProcessSchemePhase.CHOREOGRAPH_SYNAPSE:
        return 'nestle-health/strategic-media-planning'
      case ProcessSchemePhase.MEDIA_ACTIVITY_PLAN:
        return 'nestle-health/media-plan'
      case ProcessSchemePhase.NEXUS:
        return 'nestle-health/media-activation'
      case ProcessSchemePhase.ADVERITY:
        return 'nestle-health/campaign-analysis'
      default:
        return '#'
    }
  }

  return (
    <Flex align="center" justify="center" className={styles.container} data-testid="nestleHealthScience-process">
      <div
        ref={processDetailsLinkRef}
        onClick={e => {
          e.stopPropagation()
          navigate(getProcessDetailsPath())
        }}
        className={clsx(
          styles.goToProcessDetailsBtnOverlay,
          selectedPhase === ProcessSchemePhase.EMPTY && styles.hiddenLink,
        )}
      />
      <WppButton
        className={clsx(styles.goToProcessDetailsBtn, selectedPhase === ProcessSchemePhase.EMPTY && styles.hiddenLink)}
      >
        Go to process details
      </WppButton>
      <div className={styles.scaleDown}>
        <div>
          {selectedPhase !== ProcessSchemePhase.EMPTY && (
            <img
              alt=""
              src={decorativeCircleImgSrc}
              className={clsx(styles.decorativeCircle, decorativeCircleRotatione)}
            />
          )}
          <div
            className={clsx(
              styles.centrealradialGradient,
              selectedPhase !== ProcessSchemePhase.EMPTY && styles.activeCentrealradialGradient,
            )}
          />
          <img
            alt=""
            src={centralIllustrationSrc}
            className={clsx(
              styles.centralIllustration,
              selectedPhase !== ProcessSchemePhase.EMPTY && styles.centralIllustrationExpanded,
            )}
          />
          <ProcessScheme
            selectedPhase={selectedPhase}
            onSelectPhase={section => setSelectedPhase(section)}
            processDetailsLinkRef={processDetailsLinkRef}
          />
          <div>
            <WppTypography type="l-strong" className={styles.schemeCenterTitle}>
              OPEN DATA SPINE
            </WppTypography>
          </div>
          <div className={clsx(styles.phaseInfo, styles.phase1Info)}>
            <ProcessPhaseInfo
              minimized={
                selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.WHEN_NECESSARY
              }
              active={selectedPhase === ProcessSchemePhase.WHEN_NECESSARY}
              tag="BEP 1-2"
              justifyTags="center"
              title="The Big Brief Idea"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.WHEN_NECESSARY)}
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase2Info,
              selectedPhase === ProcessSchemePhase.SYNAPSE && styles.activePhase2Info,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE}
              active={selectedPhase === ProcessSchemePhase.SYNAPSE}
              tag="prerequisites to BEP 3"
              mroiTooltipText={mroiTooltipText}
              title={'Portfolio Allocation and\n Communication Strategy'}
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE)}
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase3Info,
              selectedPhase === ProcessSchemePhase.SYNAPSE_2 && styles.activePhase3Info,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE_2}
              active={selectedPhase === ProcessSchemePhase.SYNAPSE_2}
              tag="BEP 3"
              mroiTooltipText={mroiTooltipText}
              title="Campaign Brief"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE_2)}
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase4Info,
              selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB && styles.activePhase4Info,
            )}
          >
            <ProcessPhaseInfo
              minimized={
                selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.BAV_AUDIENCE_DB
              }
              active={selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB}
              tag="BEP 3"
              title="Campaign Strategy"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.BAV_AUDIENCE_DB)}
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase5Info,
              selectedPhase === ProcessSchemePhase.SYNAPSE_3 && styles.activePhase5Info,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE_3}
              active={selectedPhase === ProcessSchemePhase.SYNAPSE_3}
              tag="BEP 3"
              title="Campaign Budget Setting"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE_3)}
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase6Info,
              selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && styles.activePhase6Info,
            )}
          >
            <ProcessPhaseInfo
              minimized={
                selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.CHOREOGRAPH_SYNAPSE
              }
              active={selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE}
              tag="BEP 3"
              title="Strategic Media Planning"
              justifyTags="end"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.CHOREOGRAPH_SYNAPSE)}
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase7Info,
              selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN && styles.activePhase7Info,
            )}
          >
            <ProcessPhaseInfo
              minimized={
                selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.MEDIA_ACTIVITY_PLAN
              }
              active={selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN}
              tag="BEP 4"
              title="Media Plan"
              justifyTags="end"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.MEDIA_ACTIVITY_PLAN)}
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase8Info,
              selectedPhase === ProcessSchemePhase.NEXUS && styles.activePhase8Info,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.NEXUS}
              active={selectedPhase === ProcessSchemePhase.NEXUS}
              title="Media Activation"
              titleTooltipText={'This phase it iterative, it is\nenriched by outcomes from\nCampaign analysis phase'}
              justifyTags="end"
              showRepeatIcon
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.NEXUS)}
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase9Info,
              selectedPhase === ProcessSchemePhase.ADVERITY && styles.activePhase9Info,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.ADVERITY}
              active={selectedPhase === ProcessSchemePhase.ADVERITY}
              mroiTooltipText={mroiTooltipText}
              title="Campaign Analysis"
              titleTooltipText={
                'This phase it iterative, its outcomes\nbecome inputs of Media Activation\nand Campaign Brief phases'
              }
              justifyTags="end"
              showRepeatIcon
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.ADVERITY)}
            />
          </div>
        </div>
        {selectedPhase === ProcessSchemePhase.EMPTY && (
          <img alt="" src={hintArrow1Src} className={clsx(styles.hintArrow, styles.hintArrow1)} />
        )}
        {/* {selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && (
          <img alt='' src={hintArrow1_1Src} className={clsx(styles.hintArrow, styles.hintArrow1_1)} />
        )} */}
        {/* {selectedPhase === ProcessSchemePhase.ADVERITY && (
          <img alt='' src={hintArrow1_2Src} className={clsx(styles.hintArrow, styles.hintArrow1_2)} />
        )} */}
        {selectedPhase === ProcessSchemePhase.EMPTY && (
          <div className={styles.postCampaignInsightHint}>
            <WppTooltip text="Post-campaign insights are...">
              <WppTypography type="xs-midi" className={styles.postCampaignInsightHintText}>
                Post-campaign insights
              </WppTypography>
            </WppTooltip>
          </div>
        )}
        {/* {(selectedPhase === ProcessSchemePhase.NEXUS || selectedPhase === ProcessSchemePhase.ADVERITY) && (
          <>
            <img alt='' src={hintArrow2Src} className={clsx(styles.hintArrow, styles.hintArrow2)} />
            <WppTypography type="xs-midi" className={styles.insightsGeneratedHintText}>
              Campaign Analysis: Insights are generated
            </WppTypography>
          </>
        )} */}
        {/* {selectedPhase === ProcessSchemePhase.ADVERITY && (
          <>
            <img alt='' src={hintArrow3Src} className={clsx(styles.hintArrow, styles.hintArrow3)} />
            <WppTypography type="xs-midi" className={styles.insightsGeneratedHintText2}>
              Campaign Analysis: Insights are generated
            </WppTypography>
          </>
        )} */}
        <WppTypography type="2xs-strong" className={styles.whenNecessaryText}>
          when necessary
        </WppTypography>
      </div>
    </Flex>
  )
}
