import { WppTypography, WppIconArrow } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/asml/ASMLProcess.module.scss'
import { ReactComponent as ChartIcon } from 'pages/customProcess/components/asml/assets/icons/chart-icon.svg'
import { ReactComponent as MagnifyIcon } from 'pages/customProcess/components/asml/assets/icons/magnify-icon.svg'
import { ReactComponent as RocketIcon } from 'pages/customProcess/components/asml/assets/icons/rocket-icon.svg'
import { ReactComponent as TargetIcon } from 'pages/customProcess/components/asml/assets/icons/target-icon.svg'
import { ReactComponent as WppAsmlLogo } from 'pages/customProcess/components/asml/assets/wpp-asml-logo.svg'
import { Phases } from 'pages/customProcess/components/asml/types'

export const ASMLProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const discoverCardRef = useRef<HTMLDivElement>(null)
  const defineCardRef = useRef<HTMLDivElement>(null)
  const pilotCardRef = useRef<HTMLDivElement>(null)
  const scaleCardRef = useRef<HTMLDivElement>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
    discoverCardRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    defineCardRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    pilotCardRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    scaleCardRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className={styles.root} data-testid="asml-process">
      <Flex className={styles.decorativeBorders}>
        <div className={styles.quarterBorderBox} />
        <div className={styles.quarterBorderBox} />
        <div className={styles.quarterBorderBox} />
        <div className={clsx(styles.quarterBorderBox, styles.quarterBorderBoxLast)} />
      </Flex>
      <div className={styles.pageContent}>
        <div>
          <WppTypography tag="h1" className={styles.title}>
            ASML Operating Model
          </WppTypography>
          <WppTypography tag="p" className={styles.description}>
            {
              'Our co-creation process to develop the ideal communication operating model will enable deep-dives across these topics.\n\nBased on the learnings and sessions we will jointly define the vision, north star and design principles for your desired operating model. Together we will shape the path of how great would look like and meet ASML’s specific needs and requirements.'
            }
          </WppTypography>
        </div>
        <Flex>
          <Link
            to="/custom/asml/discover"
            className={clsx(
              styles.phaseCard,
              styles.phase1CardBg,
              !!activePhase && activePhase !== Phases.Discover && styles.phaseCardConcealed,
            )}
            onMouseEnter={handlePhaseEnter(Phases.Discover)}
            onMouseLeave={handlePhaseLeave}
          >
            <div className={styles.phaseCardContent} ref={discoverCardRef}>
              <MagnifyIcon className={styles.phaseIcon} stroke="var(--wpp-grey-color-000)" />
              <WppTypography tag="h1" className={clsx(styles.phaseCardTitle, styles.discoverPhaseTitle)}>
                Discover
              </WppTypography>
              <div className={styles.titleUnderline} />
              <ul className={clsx(styles.list, activePhase === Phases.Discover && styles.fullOpacity)}>
                <li>Integrated Discovery Phase</li>
                <li>EX+CX = BX</li>
              </ul>
            </div>
            <Flex align="center" justify="center" className={styles.phaseButton}>
              <WppIconArrow className={styles.phaseButtonIcon} width={24} color="var(--wpp-grey-color-000)" />
            </Flex>
          </Link>
          <Link
            to="/custom/asml/define"
            className={clsx(
              styles.phaseCard,
              styles.phase2CardBg,
              !!activePhase && activePhase !== Phases.Define && styles.phaseCardConcealed,
            )}
            onMouseEnter={handlePhaseEnter(Phases.Define)}
            onMouseLeave={handlePhaseLeave}
          >
            <div className={styles.phaseCardContent} ref={defineCardRef}>
              <TargetIcon className={styles.phaseIcon} stroke="var(--wpp-grey-color-000)" />
              <WppTypography tag="h1" className={clsx(styles.phaseCardTitle, styles.definePhaseTitle)}>
                Define
              </WppTypography>
              <div className={styles.titleUnderline} />
              <ul className={clsx(styles.list, activePhase === Phases.Define && styles.fullOpacity)}>
                <li>Run Project Roadmap FY24 prioritization</li>
                <li>Vision & Objective setting</li>
              </ul>
            </div>
            <Flex align="center" justify="center" className={styles.phaseButton}>
              <WppIconArrow className={styles.phaseButtonIcon} width={24} color="var(--wpp-grey-color-000)" />
            </Flex>
          </Link>
          <Link
            to="/custom/asml/pilot"
            className={clsx(
              styles.phaseCard,
              styles.phase3CardBg,
              !!activePhase && activePhase !== Phases.Pilot && styles.phaseCardConcealed,
            )}
            onMouseEnter={handlePhaseEnter(Phases.Pilot)}
            onMouseLeave={handlePhaseLeave}
          >
            <div className={styles.phaseCardContent} ref={pilotCardRef}>
              <RocketIcon className={styles.phaseIcon} stroke="var(--wpp-grey-color-000)" />
              <WppTypography tag="h1" className={clsx(styles.phaseCardTitle, styles.pilotPhaseTitle)}>
                Pilot
              </WppTypography>
              <div className={styles.titleUnderline} />
              <ul className={clsx(styles.listPilot, activePhase === Phases.Pilot && styles.fullOpacity)}>
                <li>Communications roadmap</li>
                <li>Community roadmap</li>
                <li>Employee roadmap</li>
                <li>Talent acquisition roadmap</li>
                <li>Digital services roadmap</li>
                <li>Operations roadmap</li>
              </ul>
            </div>
            <Flex align="center" justify="center" className={styles.phaseButton}>
              <WppIconArrow className={styles.phaseButtonIcon} width={24} color="var(--wpp-grey-color-000)" />
            </Flex>
          </Link>
          <Link
            to="/custom/asml/scale"
            className={clsx(
              styles.phaseCard,
              styles.phase4CardBg,
              !!activePhase && activePhase !== Phases.Scale && styles.phaseCardConcealed,
            )}
            onMouseEnter={handlePhaseEnter(Phases.Scale)}
            onMouseLeave={handlePhaseLeave}
          >
            <div className={styles.phaseCardContent} ref={scaleCardRef}>
              <ChartIcon className={styles.phaseIconChart} stroke="var(--wpp-grey-color-000)" />
              <WppTypography tag="h1" className={clsx(styles.phaseCardTitle, styles.scalePhaseTitle)}>
                Scale
              </WppTypography>
              <div className={styles.titleUnderline} />
              <ul className={clsx(styles.list, activePhase === Phases.Scale && styles.fullOpacity)}>
                <li>Learnings / Optimisations / Implementations</li>
                <li>Roadmap Development & Pilots</li>
              </ul>
              <Flex align="center" justify="center" className={styles.phaseButton}>
                <WppIconArrow className={styles.phaseButtonIcon} width={24} color="var(--wpp-grey-color-000)" />
              </Flex>
            </div>
          </Link>
        </Flex>
        <Flex justify="end">
          <WppAsmlLogo className={styles.footerLogo} />
        </Flex>
      </div>
    </div>
  )
}
