import { WppButton } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular5/Circular5Process.module.scss'
import { Circular5Svg } from 'pages/processBuilder/templates/circular5/Circular5Svg'
import { ProcessFormConfig } from 'types/process/process'

export const Circular5Process = ({ config }: { config: ProcessFormConfig }) => {
  const [activeGroup, setActiveGroup] = useState<MayBeNull<number>>(null)
  const [activePhase, setActivePhase] = useState<MayBeNull<number>>(null)

  const handleGroupEnter = useCallback(
    (phase: number) => () => {
      !activePhase && setActiveGroup(phase)
    },
    [activePhase],
  )

  const handleGroupLeave = useCallback(() => {
    setActiveGroup(null)
  }, [])

  const handlePhaseEnter = useCallback(
    (point: number) => () => {
      setActivePhase(point)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <>
      <Flex
        align="center"
        justify="center"
        className={styles.root}
        id="processRoot"
        data-testid="circular-five-process-container"
      >
        <Circular5Svg
          onGroupEnter={handleGroupEnter}
          onGroupLeave={handleGroupLeave}
          onPhaseEnter={handlePhaseEnter}
          onPhaseLeave={handlePhaseLeave}
          activeGroup={activeGroup}
          activePhase={activePhase}
          config={config}
        />

        {config?.button?.isVisible && (
          <Link to={config?.button?.url || ''} className={styles.projectLink}>
            <WppButton
              data-testid="circular-process-btn"
              className={styles.projectButton}
              style={{ background: config?.button?.colors?.[0], color: config?.button?.colors?.[1] }}
            >
              {config?.button?.title}
            </WppButton>
          </Link>
        )}
      </Flex>
      <div className={styles.popupRoot} id="phasePopupRoot" data-testid="circular-process-popup-container" />
    </>
  )
}
