import { WppButton } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/tapestry/TapestryProcess.module.scss'
import { TapestrySvg } from 'pages/customProcess/components/tapestry/TapestrySvg'
import { Phases } from 'pages/customProcess/components/tapestry/types'
import { routesManager } from 'utils/routesManager'

export const TapestryProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <>
      <div className={styles.buttonWrapper}>
        <div className={styles.projectButtonContainer}>
          <Link to={routesManager.systemUrls.orchestration.project.getUrl('64f9e698-594b-4058-a232-a5608e00d809')}>
            <WppButton className={styles.projectButton}>Go to project</WppButton>
          </Link>
        </div>
      </div>
      <Flex align="center" justify="center" className={styles.processSchemeContainer} data-testid="tapestry-process">
        <TapestrySvg
          onPhaseEnter={handlePhaseEnter}
          onPhaseLeave={handlePhaseLeave}
          activePhase={activePhase}
          className={styles.svg}
        />
      </Flex>
    </>
  )
}
