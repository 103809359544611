import { WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/aldi/AldiProcess.module.scss'
import { routesManager } from 'utils/routesManager'

const processItems = [
  { process: 'grow', section: 'grow' },
  { process: 'plan', section: 'optimise' },
  { process: 'translate', section: 'optimise' },
  { process: 'sprintPlanning', section: 'optimise' },
  { process: 'report', section: 'insight' },
  { process: 'learn', section: 'insight' },
  { process: 'optimize', section: 'insight' },
]

const sectionItems = ['grow', 'optimise', 'insight']

export const AldiProcess = () => {
  const [activeSection, setActiveSection] = useState('')

  const checkIsActive = useCallback(
    (section: string) => (activeSection === section ? styles['section--active'] : ''),
    [activeSection],
  )

  return (
    <Flex align="center" justify="center" data-testid="aldi-process">
      <Link to={routesManager.systemUrls.orchestration.root.url}>
        <WppButton className={styles.goToProcessDetailsBtn}>Go to process details</WppButton>
      </Link>
      <div className={styles.mainWrapper}>
        {processItems.map(item => (
          <div
            key={item.process}
            className={clsx(styles.process, styles[`process--${item.process}`], checkIsActive(item.section))}
            onMouseOver={() => setActiveSection(item.section)}
            onMouseLeave={() => setActiveSection('')}
          />
        ))}
        <div className={styles.mainContainer}>
          {sectionItems.map(section => (
            <div
              key={section}
              className={clsx(styles.section, styles[`section--${section}`], checkIsActive(section))}
            />
          ))}
        </div>
      </div>
    </Flex>
  )
}
