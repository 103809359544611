import { WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { ReactComponent as InfoIcon } from 'assets/info-icon.svg'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/phaseBuilder/additionalInformation/AdditionalInformation.module.scss'
import {
  showAdditionalInformationSideModal,
  hideAdditionalInformationSideModal,
  useAdditionalInformationSideModal,
} from 'pages/phaseBuilder/additionalInformation/additionalInformationSideModal/AdditionalInformationSideModal'
import { FormAdditionalInformation, PhaseAdditionalInformationVariant } from 'types/phase/phase'
import { getPreviewImageUrl } from 'utils/builder'

interface Props {
  additionalInformation: FormAdditionalInformation
}

export const AdditionalInformation = ({ additionalInformation }: Props) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)
  const { visible: isModalVisible } = useAdditionalInformationSideModal()

  const handleShowTooltip = () => {
    setIsTooltipShown(true)
  }

  const HandleHideTooltip = () => {
    setIsTooltipShown(false)
  }

  const handleToggleSideModal = useCallback(() => {
    if (additionalInformation?.variant === PhaseAdditionalInformationVariant.TOOLTIP) return

    if (isModalVisible) {
      hideAdditionalInformationSideModal()
    } else {
      showAdditionalInformationSideModal({ additionalInformation })
    }
  }, [additionalInformation, isModalVisible])

  const handleTooltipOnShow = useCallback(() => {
    if (
      additionalInformation?.variant === PhaseAdditionalInformationVariant.SIDEBAR &&
      additionalInformation?.iconTooltip
    ) {
      return
    }
    return false
  }, [additionalInformation?.variant, additionalInformation?.iconTooltip])

  if (!additionalInformation?.isEnabled) return null

  return (
    <Flex gap={9}>
      {additionalInformation?.variant === PhaseAdditionalInformationVariant.TOOLTIP &&
        additionalInformation?.image?.[0] && (
          <img
            src={getPreviewImageUrl(additionalInformation.image)}
            alt=""
            className={clsx(styles.tooltipImg, isTooltipShown && styles.tooltipVisible)}
            data-testid="tooltip-image"
          />
        )}
      <WppTooltip
        config={{
          placement: 'left',
          arrow: false,
          allowHTML: true,
          onShow: handleTooltipOnShow,
        }}
      >
        <div
          slot="tooltip-content"
          className={styles.iconTooltip}
          style={{ backgroundColor: additionalInformation?.colors?.[7] }}
        >
          <WppTypography
            slot="tooltip-content"
            type="m-strong"
            className={styles.iconTooltipText}
            style={{ color: additionalInformation?.colors?.[8] }}
          >
            {additionalInformation?.iconTooltip}
          </WppTypography>
        </div>
        <Flex
          justify="center"
          align="center"
          className={clsx(styles.infoTrigger, {
            [styles.sideModalTrigger]: additionalInformation?.variant === PhaseAdditionalInformationVariant.SIDEBAR,
          })}
          style={{
            backgroundColor: additionalInformation?.colors?.[0],
          }}
          onMouseEnter={handleShowTooltip}
          onMouseLeave={HandleHideTooltip}
          onClick={handleToggleSideModal}
          data-testid="info-trigger"
        >
          {additionalInformation?.iconImage?.[0] ? (
            <img
              src={getPreviewImageUrl(additionalInformation.iconImage)}
              className={styles.icon}
              alt=""
              data-testid="icon-image"
            />
          ) : (
            <InfoIcon
              width="36"
              height="36"
              stroke={additionalInformation?.colors?.[1] || '#ffffff'}
              data-testid="info-icon"
            />
          )}
        </Flex>
      </WppTooltip>
    </Flex>
  )
}
