import {
  WppTypography,
  WppActionButton,
  WppIconClose,
  WppDivider,
  WppButton,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as HeaderDecor } from 'pages/customProcess/components/WBA/assets/header-decor.svg'
import { WBAProcessBg } from 'pages/customProcess/components/WBA/assets/wba-process-bg'
import { HubsContent } from 'pages/customProcess/components/WBA/Content'
import { Scheme } from 'pages/customProcess/components/WBA/scheme/Scheme'
import { Phases, WBAProps } from 'pages/customProcess/components/WBA/types'
import styles from 'pages/customProcess/components/WBA/WBAProcess.module.scss'

export const WBAProcess = ({ hub = 'pharm' }: WBAProps) => {
  const [hoveredPhase, setHoveredPhase] = useState<Phases | null>(null)
  const [activePhase, setActivePhase] = useState<Phases | null>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setHoveredPhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setHoveredPhase(null)
  }, [])

  const handlePhaseClick = useCallback(
    (phase: Phases) => () => {
      if (activePhase === phase) {
        setActivePhase(null)
        return
      }
      setActivePhase(phase)
    },
    [activePhase],
  )

  return (
    <div className={styles.root} style={HubsContent[hub].colors} data-testid={`wba-${hub}-process`}>
      <Flex className={styles.pageContent} direction="column" gap={28}>
        <Flex className={styles.header} justify="between" align="center">
          <div className={styles.headerText}>
            <div>
              <WppTypography type="3xl-heading" className={styles.title}>
                ONEOpenFlow
              </WppTypography>
            </div>
            <WppTypography type="l-body" className={styles.subtitle}>
              {'United as ONE Powered by Open\nIntelligence Flow for Restless Creation'}
            </WppTypography>
          </div>
          <HeaderDecor />
          <div className={clsx(styles.logoWrapper, { [styles.logoWrapperNo7]: hub === 'no7Beauty' })}>
            <img src={HubsContent[hub].logo} alt="logo" className={styles.logo} />
          </div>
        </Flex>
        <Flex className={styles.content}>
          <div className={clsx(styles.schemeWrapper, activePhase && styles.contentBgLeftOffset)}>
            <WBAProcessBg className={clsx(styles.background, activePhase && styles.backgroundLeftOffset)} />

            <Scheme
              className={clsx(styles.scheme, activePhase && styles.schemeLeftOffset)}
              activePhase={activePhase}
              hoveredPhase={hoveredPhase}
              onPhaseMouseEnter={handlePhaseEnter}
              onPhaseMouseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseClick}
              arrowAnimation={HubsContent[hub].animation}
            />
          </div>
          <div className={clsx(styles.phaseInfoSection, activePhase && styles.phaseInfoSectionVisible)}>
            <Flex justify="end" className={styles.phaseInfoHeader}>
              <WppActionButton variant="secondary" onClick={() => setActivePhase(null)}>
                <WppIconClose slot="icon-start" className={styles.closeIcon} />
              </WppActionButton>
            </Flex>
            {activePhase && (
              <Flex direction="column" gap={16} justify="between" className={styles.phaseInfoMainContent}>
                <div>
                  <WppTypography type="xl-heading" className={clsx(styles.phaseInfoText, styles.mb8)}>
                    {HubsContent[hub].phases[activePhase].title}
                  </WppTypography>
                  <WppTypography type="s-body" className={styles.phaseInfoText}>
                    {HubsContent[hub].phases[activePhase].description1}
                  </WppTypography>
                  <WppDivider className={styles.divider} />
                  <WppTypography type="s-body" className={clsx(styles.phaseInfoText, styles.preWrap)}>
                    {HubsContent[hub].phases[activePhase].description2}
                  </WppTypography>
                  <WppDivider className={styles.divider} />
                  <WppTypography type="s-strong" className={clsx(styles.phaseInfoText, styles.mb8)}>
                    Output:
                  </WppTypography>
                  <ul className={styles.outputList}>
                    {HubsContent[hub].phases[activePhase].output.map((output, index) => (
                      <li key={index}>
                        <WppTypography type="s-body" className={styles.phaseInfoText}>
                          {output}
                        </WppTypography>
                      </li>
                    ))}
                  </ul>
                </div>
                <Flex gap={16}>
                  {HubsContent[hub].phases[activePhase].buttons.map((button, index) => (
                    <Link key={index} to={button.url} className={styles.phaseButtonLinkWrapper}>
                      <WppButton className={styles.phaseButton}>{button.title}</WppButton>
                    </Link>
                  ))}
                </Flex>
              </Flex>
            )}
          </div>
        </Flex>
      </Flex>
    </div>
  )
}
