import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'pages/phaseBuilder/additionalInformation/additionalInformationSideModal/additionalInformationSection/AdditionalInformationSection.module.scss'
import { FormAdditionalInformationSection } from 'types/phase/phase'
import { getPreviewImageUrl } from 'utils/builder'

export const AdditionalInformationSection = ({
  section,
  isLastSection,
}: {
  section: FormAdditionalInformationSection
  isLastSection?: boolean
}) => {
  return (
    <div
      className={clsx(styles.section, { [styles.lastSection]: isLastSection })}
      style={{ borderColor: section?.colors?.[1] }}
      data-testid="section"
    >
      <WppTypography
        type="m-strong"
        className={styles.title}
        style={{ color: section?.colors?.[0] }}
        data-testid="section-title"
      >
        {section?.title}
      </WppTypography>
      {section?.image?.[0] && (
        <img
          src={getPreviewImageUrl(section?.image)}
          alt=""
          className={styles.sectionContentImg}
          data-testid="section-img"
        />
      )}
    </div>
  )
}
