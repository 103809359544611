import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import deliverConcealedImgSrc from 'pages/customProcess/components/lvmh/assets/deliver-concealed.png'
import designConcealedImgSrc from 'pages/customProcess/components/lvmh/assets/design-concealed.png'
import discoverConcealedImgSrc from 'pages/customProcess/components/lvmh/assets/discover-concealed.png'
import processSchemeBorderImgSrc from 'pages/customProcess/components/lvmh/assets/process-scheme-border.png'
import ProcessSchemeCenterIllustrationImgSrc from 'pages/customProcess/components/lvmh/assets/process-scheme-center-illustration.png'
import { LvmhProcessDeliverSvg } from 'pages/customProcess/components/lvmh/components/LvmhProcessDeliverSvg'
import { LvmhProcessDesignSvg } from 'pages/customProcess/components/lvmh/components/LvmhProcessDesignSvg'
import { LvmhProcessDiscoverSvg } from 'pages/customProcess/components/lvmh/components/LvmhProcessDiscoverSvg'
import styles from 'pages/customProcess/components/lvmh/LvmhProcess.module.scss'
import { Phases } from 'pages/customProcess/components/lvmh/types'

export const LvmhProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <Flex direction="column" align="center" className={styles.root} data-testid="lvmh-process">
      <h1 className={styles.title}>
        Luxury planning framework created for LVMH Maisons, for planners, co-creators & strategists
      </h1>
      <div className={styles.processSchemeContainer}>
        <Flex justify="center" align="center" className={styles.fullWH}>
          <div className={styles.processScheme}>
            <img alt="" src={processSchemeBorderImgSrc} className={styles.fullWH} />
            <img
              alt=""
              src={ProcessSchemeCenterIllustrationImgSrc}
              className={clsx(
                styles.processSchemeCenterIllustration,
                activePhase && styles.minimizedCenterIllustration,
              )}
            />
            {activePhase !== null && activePhase !== Phases.Discover && (
              <img alt="" src={discoverConcealedImgSrc} className={styles.discoverConcealed} />
            )}
            {activePhase !== null && activePhase !== Phases.Design && (
              <img alt="" src={designConcealedImgSrc} className={styles.designConcealed} />
            )}
            {activePhase !== null && activePhase !== Phases.Deliver && (
              <img alt="" src={deliverConcealedImgSrc} className={styles.deliverConcealed} />
            )}
            <div className={styles.discoverSection}>
              <LvmhProcessDiscoverSvg
                activePhase={activePhase}
                onPhaseEnter={handlePhaseEnter}
                onPhaseLeave={handlePhaseLeave}
              />
            </div>
            <div className={styles.designSection}>
              <LvmhProcessDesignSvg
                activePhase={activePhase}
                onPhaseEnter={handlePhaseEnter}
                onPhaseLeave={handlePhaseLeave}
              />
            </div>
            <div className={styles.deliverSection}>
              <LvmhProcessDeliverSvg
                activePhase={activePhase}
                onPhaseEnter={handlePhaseEnter}
                onPhaseLeave={handlePhaseLeave}
              />
            </div>
          </div>
        </Flex>
      </div>
    </Flex>
  )
}
