import { WppTypography, WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import centralIllustrationSrc from 'assets/process/nestle/central-illustration.svg'
import { Flex } from 'components/common/flex/Flex'
import { ProcessSchemePhase } from 'pages/customProcess/components/nestle/constants'
import styles from 'pages/customProcess/components/nestle/NestleProcess.module.scss'
import hintArrow1_1Src from 'pages/customProcess/components/nestle/processScheme/assets/hint-arrow1-1.svg'
import hintArrow1_2Src from 'pages/customProcess/components/nestle/processScheme/assets/hint-arrow1-2.svg'
import hintArrow1Src from 'pages/customProcess/components/nestle/processScheme/assets/hint-arrow1.svg'
import hintArrow2Src from 'pages/customProcess/components/nestle/processScheme/assets/hint-arrow2.svg'
import hintArrow3Src from 'pages/customProcess/components/nestle/processScheme/assets/hint-arrow3.svg'
import { ProcessPhaseInfo } from 'pages/customProcess/components/nestle/processScheme/processPhaseInfo/ProcessPhaseInfo'
import { ProcessScheme } from 'pages/customProcess/components/nestle/processScheme/ProcessScheme'

const mroiTooltipText = 'MROI:\n•  Virtuous Insights\n•  Budget Allocation and Simulation\n• Strategic Learnings'

export const NestleProcess = () => {
  const [selectedPhase, setSelectedPhase] = useState<ProcessSchemePhase>(ProcessSchemePhase.EMPTY)

  return (
    <Flex align="center" justify="center" className={styles.container} data-testid="nestle-process">
      <div>
        <div
          className={clsx(
            styles.centrealradialGradient,
            selectedPhase !== ProcessSchemePhase.EMPTY && styles.activeCentrealradialGradient,
          )}
        />
        <img
          alt=""
          src={centralIllustrationSrc}
          className={clsx(
            styles.centralIllustration,
            selectedPhase !== ProcessSchemePhase.EMPTY && styles.centralIllustrationExpanded,
          )}
        />
        <ProcessScheme selectedPhase={selectedPhase} onSelectPhase={section => setSelectedPhase(section)} />
        <div>
          <WppTypography type="l-strong" className={styles.schemeCenterTitle}>
            OPEN DATA SPINE
          </WppTypography>
        </div>
        <div className={clsx(styles.phaseInfo, styles.phase1Info)}>
          <ProcessPhaseInfo
            minimized={
              selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.WHEN_NECESSARY
            }
            active={selectedPhase === ProcessSchemePhase.WHEN_NECESSARY}
            tag="BEP 1-2"
            justifyTags="center"
            title="The Big Brief Idea"
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.WHEN_NECESSARY)}
          />
        </div>
        <div
          className={clsx(
            styles.phaseInfo,
            styles.phase2Info,
            selectedPhase === ProcessSchemePhase.SYNAPSE && styles.activePhase2Info,
          )}
        >
          <ProcessPhaseInfo
            minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE}
            active={selectedPhase === ProcessSchemePhase.SYNAPSE}
            tag="prerequisites to BEP 3"
            mroiTooltipText={mroiTooltipText}
            title={'Portfolio Allocation and\n Communication Strategy'}
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE)}
          />
        </div>
        <div
          className={clsx(
            styles.phaseInfo,
            styles.phase3Info,
            selectedPhase === ProcessSchemePhase.SYNAPSE_2 && styles.activePhase3Info,
          )}
        >
          <ProcessPhaseInfo
            minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE_2}
            active={selectedPhase === ProcessSchemePhase.SYNAPSE_2}
            tag="BEP 3"
            mroiTooltipText={mroiTooltipText}
            title="Campaign Brief"
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE_2)}
          />
        </div>
        <div
          className={clsx(
            styles.phaseInfo,
            styles.phase4Info,
            selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB && styles.activePhase4Info,
          )}
        >
          <ProcessPhaseInfo
            minimized={
              selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.BAV_AUDIENCE_DB
            }
            active={selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB}
            tag="BEP 3"
            title="Campaign Strategy"
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.BAV_AUDIENCE_DB)}
          />
        </div>
        <div
          className={clsx(
            styles.phaseInfo,
            styles.phase5Info,
            selectedPhase === ProcessSchemePhase.SYNAPSE_3 && styles.activePhase5Info,
          )}
        >
          <ProcessPhaseInfo
            minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE_3}
            active={selectedPhase === ProcessSchemePhase.SYNAPSE_3}
            tag="BEP 3"
            title="Campaign Budget Setting"
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE_3)}
          />
        </div>
        <div
          className={clsx(
            styles.phaseInfo,
            styles.phase6Info,
            selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && styles.activePhase6Info,
          )}
        >
          <ProcessPhaseInfo
            minimized={
              selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.CHOREOGRAPH_SYNAPSE
            }
            active={selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE}
            tag="BEP 3"
            title="Strategic Media Planning"
            justifyTags="end"
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.CHOREOGRAPH_SYNAPSE)}
          />
        </div>
        <div
          className={clsx(
            styles.phaseInfo,
            styles.phase7Info,
            selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN && styles.activePhase7Info,
          )}
        >
          <ProcessPhaseInfo
            minimized={
              selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.MEDIA_ACTIVITY_PLAN
            }
            active={selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN}
            tag="BEP 4"
            title="Media Plan"
            justifyTags="end"
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.MEDIA_ACTIVITY_PLAN)}
          />
        </div>
        <div
          className={clsx(
            styles.phaseInfo,
            styles.phase8Info,
            selectedPhase === ProcessSchemePhase.NEXUS && styles.activePhase8Info,
          )}
        >
          <ProcessPhaseInfo
            minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.NEXUS}
            active={selectedPhase === ProcessSchemePhase.NEXUS}
            title="Media Activation"
            titleTooltipText={'This phase it iterative, it is\nenriched by outcomes from\nCampaign analysis phase'}
            justifyTags="end"
            showRepeatIcon
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.NEXUS)}
          />
        </div>
        <div
          className={clsx(
            styles.phaseInfo,
            styles.phase9Info,
            selectedPhase === ProcessSchemePhase.ADVERITY && styles.activePhase9Info,
          )}
        >
          <ProcessPhaseInfo
            minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.ADVERITY}
            active={selectedPhase === ProcessSchemePhase.ADVERITY}
            mroiTooltipText={mroiTooltipText}
            title="Campaign Analysis"
            titleTooltipText={
              'This phase it iterative, its outcomes\nbecome inputs of Media Activation\nand Campaign Brief phases'
            }
            justifyTags="end"
            showRepeatIcon
            onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.ADVERITY)}
          />
        </div>
      </div>
      {selectedPhase === ProcessSchemePhase.EMPTY && (
        <img alt="" src={hintArrow1Src} className={clsx(styles.hintArrow, styles.hintArrow1)} />
      )}
      {selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && (
        <img alt="" src={hintArrow1_1Src} className={clsx(styles.hintArrow, styles.hintArrow1_1)} />
      )}
      {selectedPhase === ProcessSchemePhase.ADVERITY && (
        <img alt="" src={hintArrow1_2Src} className={clsx(styles.hintArrow, styles.hintArrow1_2)} />
      )}
      {(selectedPhase === ProcessSchemePhase.EMPTY ||
        selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE ||
        selectedPhase === ProcessSchemePhase.ADVERITY) && (
        <div className={styles.postCampaignInsightHint}>
          <WppTooltip text="Post-campaign insights are...">
            <WppTypography type="xs-midi" className={styles.postCampaignInsightHintText}>
              Post-campaign insights
            </WppTypography>
          </WppTooltip>
        </div>
      )}
      {(selectedPhase === ProcessSchemePhase.NEXUS || selectedPhase === ProcessSchemePhase.ADVERITY) && (
        <>
          <img alt="" src={hintArrow2Src} className={clsx(styles.hintArrow, styles.hintArrow2)} />
          <WppTypography type="xs-midi" className={styles.insightsGeneratedHintText}>
            Campaign Analysis: Insights are generated
          </WppTypography>
        </>
      )}
      {selectedPhase === ProcessSchemePhase.ADVERITY && (
        <>
          <img alt="" src={hintArrow3Src} className={clsx(styles.hintArrow, styles.hintArrow3)} />
          <WppTypography type="xs-midi" className={styles.insightsGeneratedHintText2}>
            Campaign Analysis: Insights are generated
          </WppTypography>
        </>
      )}
      <WppTypography type="2xs-strong" className={styles.whenNecessaryText}>
        when necessary
      </WppTypography>
    </Flex>
  )
}
