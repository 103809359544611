import type { RichtextChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppRichtextCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import {
  WppRichtext,
  WppIconBold,
  WppIconItalic,
  WppIconUnderline,
  WppIconStrikeThrough,
  WppIconCodeView,
  WppIconBlockquote,
  WppIconOrderedList,
  WppIconUnorderedList,
  WppIconIndentDecrease,
  WppIconIndentIncrease,
  WppIconTextAlignmentLeft,
  WppIconTextAlignmentRight,
  WppIconTextAlignmentCenter,
  WppIconTextAlignmentJustify,
  WppIconLink,
  WppIconUndo,
  WppIconRedo,
} from '@platform-ui-kit/components-library-react'
import { useCallback, forwardRef, ComponentPropsWithoutRef, useEffect } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'

export interface FormTextPaletteProps extends ComponentPropsWithoutRef<typeof Flex> {
  name: string
  className?: string
  placeholder?: string
  defaultSize?: '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl'
  charactersLimit?: number
  warningThreshold?: number
  'data-testid'?: string
}

export const FormRichText = forwardRef<HTMLWppRichtextElement, FormTextPaletteProps>(
  (
    {
      name,
      className,
      placeholder,
      defaultSize,
      charactersLimit,
      warningThreshold,
      'data-testid': dataTestId = 'form-richtext',
    },
    ref,
  ) => {
    const {
      field: { onChange: setRichText, value: richText },
    } = useField({
      name,
    })

    useEffect(() => {
      const isSimpleText = !richText || !/<[a-z][\s\S]*>/i.test(richText) // "" or !"no tag"

      // if there is no richtext and there is a defaultSize, we assume it is simple text and should have a defaultSize if is setted
      if (isSimpleText && defaultSize) {
        setRichText(`<p><span class="ql-size-${defaultSize}">${richText}</span></p>`)
      }
    })

    const handleChange = useCallback(
      (e: WppRichtextCustomEvent<RichtextChangeEventDetail>) => {
        setRichText(e.detail.value)
      },
      [setRichText],
    )

    return (
      <>
        <WppRichtext
          onWppChange={handleChange}
          ref={ref}
          className={className}
          placeholder={placeholder}
          defaultValue={richText}
          charactersLimit={charactersLimit}
          warningThreshold={warningThreshold}
          data-testid={dataTestId}
        />

        {/* TODO: remove once component library provide fix for icons tree shaker */}
        <div style={{ display: 'none' }}>
          <WppIconBold />
          <WppIconItalic />
          <WppIconUnderline />
          <WppIconStrikeThrough />
          <WppIconCodeView />
          <WppIconBlockquote />
          <WppIconOrderedList />
          <WppIconUnorderedList />
          <WppIconIndentDecrease />
          <WppIconIndentIncrease />
          <WppIconTextAlignmentLeft />
          <WppIconTextAlignmentRight />
          <WppIconTextAlignmentCenter />
          <WppIconTextAlignmentJustify />
          <WppIconLink />
          <WppIconUndo />
          <WppIconRedo />
        </div>
      </>
    )
  },
)
