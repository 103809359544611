import { WppRichtextHtml, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { MouseEventHandler, useCallback, useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular5/Circular5Process.module.scss'
import { Group } from 'pages/processBuilder/templates/circular5/group/Group'
import { getConicGradient } from 'pages/processBuilder/utils'
import { ProcessFormConfig } from 'types/process/process'
import { getPreviewImageUrl, normalizeDimensions } from 'utils/builder'

export const Circular5Svg = ({
  activeGroup,
  activePhase,
  onGroupEnter,
  onGroupLeave,
  onPhaseEnter,
  onPhaseLeave,
  config,
}: {
  activeGroup: MayBeNull<number>
  activePhase: MayBeNull<number>
  onGroupEnter: (index: number) => MouseEventHandler
  onGroupLeave: MouseEventHandler
  onPhaseEnter: (index: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
  config: ProcessFormConfig
}) => {
  const isActiveGroup = useCallback((index: number) => index === activeGroup, [activeGroup])

  const usedPhasesCount = useMemo(
    () => config.groups.reduce((accum, group) => accum + group.phasesLocalIds.length, 0),
    [config],
  )
  const groupPhaseWidth = usedPhasesCount ? 360 / usedPhasesCount : 0
  const conicGradientPhaseWidth = usedPhasesCount ? 100 / usedPhasesCount : 0

  const getGroupRotation = useCallback(() => {
    let prevGroupsWidth = 0

    return (phasesCount: number) => {
      const groupWidth = groupPhaseWidth * phasesCount
      const groupRotation = prevGroupsWidth + groupWidth / 2
      prevGroupsWidth += groupWidth

      return groupRotation
    }
  }, [groupPhaseWidth])()

  const widgetBg =
    (config.widgetBackgroundImage?.[0]
      ? `url(${getPreviewImageUrl(config.widgetBackgroundImage)})`
      : config.colors?.[1]) || '#A9BDFD'

  const logo = useMemo(() => getPreviewImageUrl(config?.logoImage), [config?.logoImage])
  const { width: logoWidth, height: logoHeight } = useMemo(
    () => normalizeDimensions(config.logoDimensions),
    [config.logoDimensions, config.logoDimensions.width, config.logoDimensions.height], //eslint-disable-line
  )
  const logoSize = useMemo(
    () =>
      Number.isInteger(activePhase)
        ? {
            width: 250,
            heigth: 250,
            x: 515,
            y: 330,
          }
        : {
            width: 476,
            heigth: 476,
            x: 402,
            y: 217,
          },
    [activePhase],
  )

  const isGroupPreview = useMemo(
    () => Number.isInteger(activeGroup) && !Number.isInteger(activePhase),
    [activeGroup, activePhase],
  )

  const groupColors = config?.groups.reduce((accum: { color: string; size: number }[], item) => {
    item?.colors?.[3] &&
      accum.push({ color: item?.colors?.[3], size: conicGradientPhaseWidth * item.phasesLocalIds.length })
    return accum
  }, [])
  const gradientRotation = groupPhaseWidth * config?.groups?.[0]?.phasesLocalIds?.length

  const borderColor = groupColors.length ? getConicGradient(groupColors, gradientRotation) : '#d6d1d1'

  return (
    <svg
      width="1280"
      height="910"
      viewBox="0 0 1280 910"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
      data-testid="circular-five-svg"
    >
      <foreignObject
        width="604"
        height="604"
        x="338"
        y="153"
        className={styles.svgMainCircle}
        style={{
          background: borderColor,
        }}
      />
      <foreignObject
        width="474"
        height="474"
        x="403"
        y="218"
        className={styles.svgMainCircle}
        style={{
          background: widgetBg,
        }}
      />

      {config.groups.map((group, index) => {
        return (
          <Group
            group={group}
            phases={config.phases}
            isActive={isActiveGroup(index)}
            activePhase={activePhase}
            activeGroup={activeGroup}
            onGroupEnter={onGroupEnter(index)}
            onGroupLeave={onGroupLeave}
            onPhaseEnter={onPhaseEnter}
            onPhaseLeave={onPhaseLeave}
            rotation={getGroupRotation(group?.phasesLocalIds.length)}
            size={group?.phasesLocalIds.length * groupPhaseWidth}
            key={index}
            groupIndex={index}
          />
        )
      })}

      <foreignObject
        width={logoSize.width}
        height={logoSize.heigth}
        x={logoSize.x}
        y={logoSize.y}
        className={styles.logoWrap}
        style={{ background: config?.colors?.[2] || '#fff' }}
        opacity={isGroupPreview ? 0 : 1}
        data-testid="preview-logo-container"
      >
        <img
          src={logo}
          alt=""
          className={styles.logo}
          style={{ visibility: logo ? 'visible' : 'hidden', width: logoWidth, height: logoHeight }}
          data-testid="preview-logo"
        />
      </foreignObject>

      <g opacity={isGroupPreview ? 1 : 0} className={styles.groupPreview} data-testid="group-preview-logo-container">
        <foreignObject width={290} height={167} x={494} y={270} style={{ background: config?.colors?.[5] }}>
          <img
            src={logo}
            alt=""
            className={styles.previewLogo}
            style={{ visibility: logo ? 'visible' : 'hidden' }}
            data-testid="group-preview-logo"
          />
        </foreignObject>

        <foreignObject
          width={290}
          height={167}
          x={494}
          y={477}
          style={{ background: config?.colors?.[5] }}
          opacity={isGroupPreview ? 1 : 0}
          className={styles.groupPreview}
          data-testid="group-info-wrapper"
        >
          {typeof activeGroup === 'number' && (
            <Flex direction="column" align="center" gap={10} data-testid="group-info">
              <WppTypography type="2xl-heading" style={{ color: config.groups?.[activeGroup].colors[1] || '#000' }}>
                {config.groups?.[activeGroup].title}
              </WppTypography>

              {config.groups?.[activeGroup]?.description && (
                <WppRichtextHtml
                  className="richTextDescription"
                  style={{ color: config.groups?.[activeGroup].colors[2] || '#000' }}
                  value={config.groups?.[activeGroup].description as string}
                />
              )}
            </Flex>
          )}
        </foreignObject>

        <line x1="403" y1="457" x2="877" y2="457" stroke={config?.colors?.[3] || '#fff'} strokeWidth={1} />
      </g>
    </svg>
  )
}
