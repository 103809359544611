import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import animatedBgWaveImgSrc from 'pages/customProcess/components/audible/assets/animated-bg-wave.gif'
import processCenterLogoImgSrc from 'pages/customProcess/components/audible/assets/process-center-logo.gif'
import processSchemeBgImgSrc from 'pages/customProcess/components/audible/assets/process-scheme-bg.png'
import { links } from 'pages/customProcess/components/audible/audibleConfig'
import styles from 'pages/customProcess/components/audible/AudibleProcess.module.scss'
import { ActivatePhaseSvg } from 'pages/customProcess/components/audible/components/ActivatePhaseSvg'
import { LearnPhaseSvg } from 'pages/customProcess/components/audible/components/LearnPhaseSvg'
import { PlanPhaseSvg } from 'pages/customProcess/components/audible/components/PlanPhaseSvg'
import { ProvokePhaseSvg } from 'pages/customProcess/components/audible/components/ProvokePhaseSvg'
import { StrategizePhaseSvg } from 'pages/customProcess/components/audible/components/StrategizePhaseSvg'
import { Phases } from 'pages/customProcess/components/audible/types'
import { routesManager } from 'utils/routesManager'

export const AudibleProcess = () => {
  const navigate = useNavigate()

  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePhaseClick = useCallback(
    (point: Phases) => () => {
      navigate(links[point])
    },
    [navigate],
  )

  return (
    <div className={styles.root} data-testid="audible-process">
      <Flex justify="center" align="center" className={styles.pageLayout}>
        <div className={clsx(styles.backArrowIconBtn, !!activePhase && styles.backArrowIconBtnDisabled)}>
          <Link to={routesManager.systemUrls.core.root.url}>
            <WppIconArrow direction="left" width={57} color="var(--wpp-grey-color-1000)" />
          </Link>
        </div>
        <img src={animatedBgWaveImgSrc} alt="" className={styles.bgWaveGif} />
        <div className={styles.processSchemeWrapper}>
          <img src={processSchemeBgImgSrc} alt="" className={clsx(styles.processSchemeBg, styles.centered)} />
          <div
            className={clsx(styles.centerBgCircle1, styles.centered, !!activePhase && styles.centerBgCircle1Active)}
          />
          <div
            className={clsx(styles.centerBgCircle2, styles.centered, !!activePhase && styles.centerBgCircle2Active)}
          />
          <div className={clsx(styles.centerBgCircle3, styles.centered)} />
          <div className={clsx(styles.provokePhase, styles.centered)}>
            <ProvokePhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseClick}
            />
          </div>
          <div className={clsx(styles.strategizePhase, styles.centered)}>
            <StrategizePhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseClick}
            />
          </div>
          <div className={clsx(styles.planPhase, styles.centered)}>
            <PlanPhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseClick}
            />
          </div>
          <div className={clsx(styles.activatePhase, styles.centered)}>
            <ActivatePhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseClick}
            />
          </div>
          <div className={clsx(styles.learnPhase, styles.centered)}>
            <LearnPhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPhaseClick={handlePhaseClick}
            />
          </div>
          <img
            src={processCenterLogoImgSrc}
            alt=""
            className={clsx(styles.processCenterLogo, !!activePhase && styles.processCenterLogoMinimized)}
          />
        </div>
      </Flex>
    </div>
  )
}
