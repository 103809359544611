import { WppActionButton, WppIconArrow, WppIconStop } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, MouseEvent } from 'react'

import styles from 'components/form/formAlignment/FormAlignment.module.scss'
import { useField } from 'hooks/form/useField'
import { BGAlignmentOptions } from 'types/common/comon'

const buttonsConfig = [
  {
    Icon: WppIconArrow,
    value: BGAlignmentOptions.LeftTop,
    iconProps: { className: styles.iconRotate45, direction: 'left' },
  },
  {
    Icon: WppIconArrow,
    value: BGAlignmentOptions.CenterTop,
    iconProps: { direction: 'top' },
  },
  {
    Icon: WppIconArrow,
    value: BGAlignmentOptions.RightTop,
    iconProps: { className: styles.iconRotate45, direction: 'top' },
  },

  {
    Icon: WppIconArrow,
    value: BGAlignmentOptions.LeftCenter,
    iconProps: { direction: 'left' },
  },
  {
    Icon: WppIconStop,
    value: BGAlignmentOptions.CenterCenter,
  },
  {
    Icon: WppIconArrow,
    value: BGAlignmentOptions.RightCenter,
    iconProps: { direction: 'right' },
  },

  {
    Icon: WppIconArrow,
    value: BGAlignmentOptions.LeftBottom,
    iconProps: { className: styles.iconRotate45, direction: 'down' },
  },
  {
    Icon: WppIconArrow,
    value: BGAlignmentOptions.CenterBottom,
    iconProps: { direction: 'down' },
  },
  {
    Icon: WppIconArrow,
    value: BGAlignmentOptions.RightBottom,
    iconProps: { className: styles.iconRotate45, direction: 'right' },
  },
]

export const FormAlignment = ({
  name,
  className,
  hidden,
  'data-testid': dataTestId = 'form-alignment',
}: {
  name: string
  className?: string
  hidden?: boolean
  'data-testid'?: string
}) => {
  const {
    field: { value: fieldValue, onChange: setAlignment },
  } = useField({
    name,
  })

  const handleClick = useCallback(
    ({ currentTarget }: MouseEvent<HTMLWppActionButtonElement>) => {
      setAlignment(currentTarget.value)
    },
    [setAlignment],
  )

  return !hidden ? (
    <div className={clsx(styles.root, className)} data-testid={dataTestId}>
      {buttonsConfig.map(({ value, Icon, iconProps }, index) => (
        <WppActionButton
          onClick={handleClick}
          value={value}
          className={clsx(styles.control, { [styles.isActive]: value === fieldValue })}
          key={index}
        >
          <Icon
            direction={iconProps?.direction as any}
            className={iconProps?.className}
            size="s"
            color={value === fieldValue ? 'var(--wpp-primary-color-400)' : 'var(--wpp-icon-color)'}
          />
        </WppActionButton>
      ))}
    </div>
  ) : null
}
