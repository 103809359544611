// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdzNf{padding:16px}.DaKQW{margin-top:-180px}@media(height <= 900px){.DaKQW{margin-top:0}}.QYuAz{background:linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.cjSbg{margin-top:-120px}.HmQww{margin-top:0}.fudL4{padding:16px 16px 64px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processPhases/pages/campaignBrief/CampaignBrief.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,iBAAA,CAEA,wBAHF,OAII,YAAA,CAAA,CAIJ,OACE,iEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,iBAAA,CAGF,OACE,YAAA,CAGF,OACE,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px;\n}\n\n.inputCardsWrapper {\n  margin-top: -180px;\n\n  @media (height <= 900px) {\n    margin-top: 0;\n  }\n}\n\n.synapseTagColor {\n  background: linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard1Wrapper {\n  margin-top: -120px;\n}\n\n.regularCard2Wrapper {\n  margin-top: 0;\n}\n\n.outputsCard {\n  padding: 16px 16px 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `pdzNf`,
	"inputCardsWrapper": `DaKQW`,
	"synapseTagColor": `QYuAz`,
	"regularCard1Wrapper": `cjSbg`,
	"regularCard2Wrapper": `HmQww`,
	"outputsCard": `fudL4`
};
export default ___CSS_LOADER_EXPORT___;
