import { WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { AdditionalInformationSection } from 'pages/phaseBuilder/additionalInformation/additionalInformationSideModal/additionalInformationSection/AdditionalInformationSection'
import styles from 'pages/phaseBuilder/additionalInformation/additionalInformationSideModal/AdditionalInformationSideModal.module.scss'
import { FormAdditionalInformation } from 'types/phase/phase'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  additionalInformation: FormAdditionalInformation
}

export const AdditionalInformationSideModal = ({ isOpen, onClose, onCloseComplete, additionalInformation }: Props) => {
  const { t } = useTranslation()

  const modalStyle = {
    '--info-bg-color': additionalInformation?.colors?.[3],
    '--info-close-icon-button-color': additionalInformation?.colors?.[4],
    '--info-close-button-hover-color': additionalInformation?.colors?.[6],
  }

  const handleCloseComplete = useCallback(() => {
    onCloseComplete()
  }, [onCloseComplete])

  return (
    <SideModal
      size="m"
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={handleCloseComplete}
      className={clsx(styles.modal, { [styles.actionsBorderless]: additionalInformation?.colors?.[3] })}
      style={modalStyle}
      data-testid="modal"
    >
      <WppTypography slot="header" type="2xl-heading" style={{ color: additionalInformation?.colors?.[2] }}>
        {additionalInformation?.title}
      </WppTypography>
      <Flex direction="column" slot="body">
        {additionalInformation?.sections.map((section, index) => (
          <AdditionalInformationSection
            section={section}
            key={index}
            isLastSection={index === additionalInformation.sections.length - 1}
          />
        ))}
      </Flex>
      <Flex slot="actions" gap={12} justify="end">
        <WppButton
          variant="secondary"
          onClick={onClose}
          className={clsx(styles.closeBtn, { [styles.closeBtnHover]: additionalInformation?.colors?.[6] })}
          style={{ color: additionalInformation?.colors?.[5] || 'var(--wpp-primary-color-500)' }}
          data-testid="close-button"
        >
          {t('common.close')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const {
  showModal: showAdditionalInformationSideModal,
  hideModal: hideAdditionalInformationSideModal,
  useModal: useAdditionalInformationSideModal,
} = createNiceModal<Props>(AdditionalInformationSideModal, 'additional-side-modal')
