import {
  WppCard,
  WppAccordion,
  WppTypography,
  WppActionButton,
  WppIconTrash,
  WppRadio,
  WppIconPlus,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEventHandler, useCallback } from 'react'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormRadioGroup } from 'components/form/formRadioGroup/FormRadioGroup'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import styles from 'pages/phaseBuilder/editPhaseModal/EditPhaseModal.module.scss'
import { colorsConfig } from 'pages/phaseBuilder/utils'
import { Templates, PhaseAdditionalInformationVariant } from 'types/phase/phase'

export const AdditionalInformationFields = ({
  index,
  isEditMode,
  onRemove,
}: {
  index: number
  isEditMode: boolean
  onRemove: MouseEventHandler
}) => {
  const { t } = useTranslation()
  const { watch, control } = useFormContext()
  const formData = watch()

  const {
    fields: sidebarSections,
    append: appendSidebarSection,
    remove: removeSidebarSection,
  } = useFieldArray({
    control,
    name: `additionalInformations.${index}.sections`,
    keyName: 'formId',
  })

  const handleAddSidebarSection = useCallback(() => {
    appendSidebarSection({
      title: '',
      image: [],
      colors: [],
    })
  }, [appendSidebarSection])

  const handleRemoveSidebarSection = useCallback(
    (index: number) => () => {
      removeSidebarSection(index)
    },
    [removeSidebarSection],
  )

  return (
    <WppCard>
      <WppAccordion withDivider={false} className={styles.accordion} data-testid="additional-info-accordion">
        <div slot="header">
          <Flex gap={8}>
            <WppTypography type="s-strong">
              {t('phase.edit_modal.additional_information_number', { number: index + 1 })}
            </WppTypography>
          </Flex>
        </div>
        <WppActionButton
          variant="primary"
          onClick={onRemove}
          slot="actions"
          className={styles.accordionActionBtn}
          data-testid="remove-additional-info-btn"
        >
          <WppIconTrash slot="icon-start" />
        </WppActionButton>

        <Flex direction="column" gap={24} className={styles.additionalInformationsFieldsWrap}>
          <FormCheckbox
            name={`additionalInformations.${index}.isEnabled`}
            labelConfig={{
              text: t('phase.edit_modal.additional_information_enabled'),
            }}
            data-testid="additional-info-enabled-checkbox"
          />

          <Controller
            render={({ field }) => (
              <FormFileUpload
                {...field}
                disabled={false}
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_additional_information_icon')}
              />
            )}
            name={`additionalInformations.${index}.iconImage`}
            control={control}
          />

          <FormPalette
            title={t('common.colors')}
            useDefaultColors={!isEditMode}
            name={`additionalInformations.${index}.colors`}
            colorsConfig={colorsConfig[formData?.template as Templates].additionalInformation}
            disableExpanding
          />

          <FormRadioGroup name={`additionalInformations.${index}.variant`}>
            <Flex gap={20}>
              <WppRadio
                value="tooltip"
                name={`additionalInformations.${index}.variant`}
                labelConfig={{
                  text: t('phase.edit_modal.additional_information_as_tooltip'),
                }}
                required
                data-testid="additional-info-tooltip-radio"
              />
              <WppRadio
                value="sidebar"
                name={`additionalInformations.${index}.variant`}
                labelConfig={{
                  text: t('phase.edit_modal.additional_information_as_sidebar'),
                }}
                required
                data-testid="additional-info-sidebar-radio"
              />
            </Flex>
          </FormRadioGroup>

          <Controller
            render={({ field }) => (
              <FormFileUpload
                {...field}
                disabled={false}
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_additional_information_image')}
                hidden={
                  formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.SIDEBAR
                }
                data-testid="additional-info-content-image-upload"
              />
            )}
            name={`additionalInformations.${index}.image`}
            control={control}
          />

          <FormTextInput
            name={`additionalInformations.${index}.title`}
            placeholder={t('phase.edit_modal.additional_information_title')}
            hidden={formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.TOOLTIP}
            data-testid="additional-info-title-input"
          />

          <FormTextInput
            name={`additionalInformations.${index}.iconTooltip`}
            placeholder={t('phase.edit_modal.additional_information_tooltip')}
            hidden={formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.TOOLTIP}
            data-testid="additional-info-tooltip-input"
          />

          {formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.SIDEBAR && (
            <WppTypography type="m-strong">
              {t('phase.edit_modal.additional_informations_sections_title')}
            </WppTypography>
          )}

          {sidebarSections.map((item, sectionIndex) => (
            <WppCard
              key={item.formId}
              className={clsx({
                [styles.displayNone]:
                  formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.TOOLTIP,
              })}
            >
              <WppAccordion
                withDivider={false}
                className={clsx(styles.accordion, {
                  [styles.displayNone]:
                    formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.TOOLTIP,
                })}
                data-testid="additional-info-section-accordion"
              >
                <WppTypography type="s-strong" slot="header">
                  {t('phase.edit_modal.section_number', { number: sectionIndex + 1 })}
                </WppTypography>
                <WppActionButton
                  className={styles.accordionActionBtn}
                  variant="primary"
                  onClick={handleRemoveSidebarSection(sectionIndex)}
                  slot="actions"
                  data-testid="additional-information-remove-section-btn"
                >
                  <WppIconTrash slot="icon-start" />
                </WppActionButton>

                <Flex direction="column" gap={10} className={styles.sectionFieldsWrap}>
                  <FormTextInput
                    name={`additionalInformations.${index}.sections.${sectionIndex}.title`}
                    placeholder={t('phase.edit_modal.section_number', { number: sectionIndex + 1 })}
                    hidden={
                      formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.TOOLTIP
                    }
                  />
                  <FormFileUpload
                    name={`additionalInformations.${index}.sections.${sectionIndex}.image`}
                    size={1}
                    acceptConfig={{
                      'image/svg+xml': ['.svg'],
                      'image/png': ['.png'],
                      'image/jpeg': ['.jpg', '.jpeg'],
                      'image/gif': ['.gif'],
                    }}
                    maxLabelLength={45}
                    maxFiles={1}
                    className={styles.fileInput}
                    label={t('phase.edit_modal.choose_additional_information_section_image')}
                    hidden={
                      formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.TOOLTIP
                    }
                  />
                  <FormPalette
                    title={t('common.colors')}
                    useDefaultColors={!isEditMode}
                    name={`additionalInformations.${index}.sections.${sectionIndex}.colors`}
                    colorsConfig={colorsConfig[formData?.template as Templates].additionalInformationSection}
                  />
                </Flex>
              </WppAccordion>
            </WppCard>
          ))}

          {formData?.additionalInformations?.[index]?.variant === PhaseAdditionalInformationVariant.SIDEBAR && (
            <WppActionButton onClick={handleAddSidebarSection} data-testid="additional-information-add-section-btn">
              <WppIconPlus slot="icon-start" />
              {t('phase.edit_modal.add_section')}
            </WppActionButton>
          )}
        </Flex>
      </WppAccordion>
    </WppCard>
  )
}
