// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inCYS::part(typography){display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:var(--lines, 1)}`, "",{"version":3,"sources":["webpack://./src/components/truncate/Truncate.module.scss"],"names":[],"mappings":"AACE,yBACE,mBAAA,CACA,2BAAA,CACA,kCAAA","sourcesContent":[".root {\n  &::part(typography) {\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: var(--lines, 1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `inCYS`
};
export default ___CSS_LOADER_EXPORT___;
