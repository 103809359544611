import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/mattrass/MattrassProcess.module.scss'
import { SvgProcess } from 'pages/customProcess/components/mattrass/SvgProcess'

export const MattrassProcess = () => {
  const [hoveredEl, setHoveredEl] = useState('')

  const phases: any[] = [
    {
      title: 'Unlock',
      description: 'Unlock more value and insights through audience activation and measurement',
      el: 'unlock',
      justify: 'start',
      align: 'end',
      class: styles.unlockNote,
    },
    {
      title: 'Maximize',
      description: 'Maximize audience distribution and outcomes across channels and platforms',
      el: 'maximize',
      justify: 'end',
      align: 'center',
      class: styles.maximizeNote,
    },
    {
      title: 'Transform',
      description: 'Transform trajectory by identifying growth opportunities and audiences',
      el: 'transform',
      justify: 'start',
      align: 'start',
      class: styles.transformNote,
    },
  ]
  return (
    <Flex
      direction="column"
      className={styles.container}
      justify="center"
      align="center"
      data-testid="mattressfirm-process"
    >
      <Flex justify="end" align="start" style={{ width: '100%' }}>
        <Link to="https://mattressfirm.wppopen.com/orchestration/project/ae442df2-eb7e-4d86-b19e-181850174daf/workflow">
          <WppButton>Go to project details</WppButton>
        </Link>
      </Flex>

      <Flex className={styles.process} align="center" justify="center">
        <Flex justify="center" className={styles.notes}>
          {phases.map(phase => (
            <Flex
              direction="column"
              key={phase.el}
              justify={phase.justify}
              align={phase.align}
              className={clsx(phase.class, { [styles.notesShow]: phase.el === hoveredEl })}
            >
              <WppTypography type="2xl-heading">{phase.title}</WppTypography>
              <WppTypography type="m-body">{phase.description}</WppTypography>
            </Flex>
          ))}
        </Flex>
        <SvgProcess activeEl={hoveredEl} setActiveEl={setHoveredEl} />
      </Flex>
    </Flex>
  )
}
