import { WppButton, WppIconGear } from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { BaseErrorState } from 'components/baseErrorState/BaseErrorState'
import { SvgNoRecentWork } from 'components/svg/SvgNoRecentWork'
import styles from 'pages/phaseBuilder/emptyState/EmptyState.module.scss'
import { routesManager } from 'utils/routesManager'

export const EmptyState = ({ isAdmin, onConfigureClick }: { isAdmin: boolean; onConfigureClick: () => void }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAdmin) {
      navigate(routesManager.notFound.url)
    }
  }, [isAdmin, navigate])

  if (!isAdmin) {
    return null
  }

  return (
    <BaseErrorState
      illustration={<SvgNoRecentWork className={styles.img} data-testid="svg-illustration" />}
      mainTitle={t('phase.empty_state.title')}
      subTitle={t('phase.empty_state.description')}
      className={styles.root}
      actions={
        <WppButton onClick={onConfigureClick} data-testid="button">
          {t('phase.empty_state.button_text')} <WppIconGear slot="icon-start" />
        </WppButton>
      }
    />
  )
}
