import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import styles from 'pages/customProcess/components/beiersdorf/BeiersdorfProcess.module.scss'
import { ProcessPhase } from 'pages/customProcess/components/beiersdorf/processScheme/constants'
import { ProcessScheme } from 'pages/customProcess/components/beiersdorf/processScheme/ProcessScheme'

export const BeiersdorfProcess = () => {
  const [processPhase, setProcessPhase] = useState(ProcessPhase.default)

  return (
    <div className={styles.container} data-testid="beiersdorf-process">
      <div className={styles.mainTitleWrapper}>
        <div>
          <WppTypography type="3xl-heading" tag="h1">
            Introducing
          </WppTypography>
        </div>
        <WppTypography type="3xl-heading" tag="h1">
          Fast CX Planning
        </WppTypography>
      </div>
      <ProcessScheme selectedPhase={processPhase} onSelectPhase={section => setProcessPhase(section)} />
      {processPhase === ProcessPhase.Grow && (
        <div className={clsx(styles.phaseDescription, styles.growDescription)}>
          <div>
            <WppTypography type="l-strong" className={styles.descriptionTitle}>
              Annual Growth Planning
            </WppTypography>
          </div>
          <div className={styles.deliveredBySection}>
            <div>
              <WppTypography type="s-strong">Delivered By:</WppTypography>
            </div>
            <div>
              <WppTypography type="s-body">Regional Fast CX Planners</WppTypography>
            </div>
          </div>
          <div>
            <WppTypography type="s-strong">System:</WppTypography>
          </div>
          <div>
            <WppTypography type="s-body">Source of Growth, Portfolio Planning, Budget Setting</WppTypography>
          </div>
        </div>
      )}
      {processPhase === ProcessPhase.Plan && (
        <div className={clsx(styles.phaseDescription, styles.planDescription)}>
          <div>
            <WppTypography type="l-strong" className={styles.descriptionTitle}>
              Campaign Planning
            </WppTypography>
          </div>
          <div className={styles.deliveredBySection}>
            <div>
              <WppTypography type="s-strong">Delivered By:</WppTypography>
            </div>
            <div>
              <WppTypography type="s-body">Regional Fast CX Planners</WppTypography>
            </div>
          </div>
          <div>
            <WppTypography type="s-strong">System:</WppTypography>
          </div>
          <div>
            <pre>
              <WppTypography type="s-body">{'AI-Audience Planning,\nCX Journey Planning'}</WppTypography>
            </pre>
          </div>
        </div>
      )}
      {processPhase === ProcessPhase.Translate && (
        <div className={clsx(styles.phaseDescription, styles.translateDescription)}>
          <div>
            <WppTypography type="l-strong" className={styles.descriptionTitle}>
              Implementation Planning
            </WppTypography>
          </div>
          <div className={styles.deliveredBySection}>
            <div>
              <WppTypography type="s-strong">Delivered By:</WppTypography>
            </div>
            <div>
              <WppTypography type="s-body">ABC / Local Markets</WppTypography>
            </div>
          </div>
          <div>
            <WppTypography type="s-strong">System:</WppTypography>
          </div>
          <div>
            <pre>
              <WppTypography type="s-body">ROI Channel Planning, Audience Translator</WppTypography>
            </pre>
          </div>
        </div>
      )}
      {processPhase === ProcessPhase.Activate && (
        <div className={clsx(styles.phaseDescription, styles.activateDescription)}>
          <div className={styles.deliveredBySection}>
            <div>
              <WppTypography type="s-strong">Delivered By:</WppTypography>
            </div>
            <div>
              <WppTypography type="s-body">ABC Hubs, Local Market Buyers</WppTypography>
            </div>
          </div>
          <div>
            <WppTypography type="s-strong">System:</WppTypography>
          </div>
          <div>
            <pre>
              <WppTypography type="s-body">Activate powered by Nexus Growth Planner</WppTypography>
            </pre>
          </div>
        </div>
      )}
      {processPhase === ProcessPhase.Optimize && (
        <div className={clsx(styles.phaseDescription, styles.optimizeDescription)}>
          <div className={styles.deliveredBySection}>
            <div>
              <WppTypography type="s-strong">Delivered By:</WppTypography>
            </div>
            <div>
              <WppTypography type="s-body">ABC Hubs</WppTypography>
            </div>
          </div>
          <div>
            <WppTypography type="s-strong">System:</WppTypography>
          </div>
          <div>
            <pre>
              <WppTypography type="s-body">Optimize powered by Co-Pilot</WppTypography>
            </pre>
          </div>
        </div>
      )}
      {processPhase === ProcessPhase.Report && (
        <div className={clsx(styles.phaseDescription, styles.reportDescription)}>
          <div className={styles.deliveredBySection}>
            <div>
              <WppTypography type="s-strong">Delivered By:</WppTypography>
            </div>
            <div>
              <WppTypography type="s-body">ABC Hubs, Local Markets</WppTypography>
            </div>
          </div>
          <div>
            <WppTypography type="s-strong">System:</WppTypography>
          </div>
          <div>
            <pre>
              <WppTypography type="s-body">EUNA Dashboard, MediaX integration</WppTypography>
            </pre>
          </div>
        </div>
      )}
      {processPhase === ProcessPhase.Learn && (
        <div className={clsx(styles.phaseDescription, styles.learnDescription)}>
          <div>
            <WppTypography type="l-strong" className={styles.descriptionTitle}>
              Measurement
            </WppTypography>
          </div>
          <div className={styles.deliveredBySection}>
            <div>
              <WppTypography type="s-strong">Delivered By:</WppTypography>
            </div>
            <div>
              <WppTypography type="s-body">ABC / Local Markets</WppTypography>
            </div>
          </div>
          <div>
            <WppTypography type="s-strong">System:</WppTypography>
          </div>
          <div>
            <pre>
              <WppTypography type="s-body">Calibrated Portfolio</WppTypography>
            </pre>
          </div>
        </div>
      )}
    </div>
  )
}
