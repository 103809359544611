import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { phaseLinks } from 'pages/customProcess/components/mediaMarktSaturn/MediaMarktSaturnConfig'
import styles from 'pages/customProcess/components/mediaMarktSaturn/MediaMarktSaturnProcess.module.scss'
import { MediaMarktSaturnSvg } from 'pages/customProcess/components/mediaMarktSaturn/MediaMarktSaturnSvg'
import { PhaseGroups, Phases } from 'pages/customProcess/components/mediaMarktSaturn/types'

export const MediaMarktSaturnProcess = () => {
  const navigate = useNavigate()

  const [activePhaseGroup, setActivePhaseGroup] = useState<MayBeNull<PhaseGroups>>(null)
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseGroupEnter = useCallback(
    (phaseGroup: PhaseGroups) => () => {
      setActivePhaseGroup(phaseGroup)
    },
    [],
  )

  const handlePhaseGroupLeave = useCallback(() => {
    setActivePhaseGroup(null)
  }, [])

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePhaseClick = useCallback(
    (phase: Phases) => () => {
      navigate(phaseLinks[phase])
    },
    [navigate],
  )

  return (
    <Flex className={styles.root} justify="center" align="center" data-testid="mediaMarktSaturn-process">
      <div className={clsx(styles.bgOverlay, (!!activePhaseGroup || !!activePhase) && styles.bgOverlayActive)} />
      <MediaMarktSaturnSvg
        activePhaseGroup={activePhaseGroup}
        onPhaseGroupEnter={handlePhaseGroupEnter}
        onPhaseGropuLeave={handlePhaseGroupLeave}
        activePhase={activePhase}
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPhaseClick={handlePhaseClick}
      />
    </Flex>
  )
}
