import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as ListIcon } from 'pages/customWidget/cocaColaChina/process/assets/icons/list.svg'
import { ReactComponent as ToolsIcon } from 'pages/customWidget/cocaColaChina/process/assets/icons/tools.svg'
import styles from 'pages/customWidget/cocaColaChina/process/CocaColaChinaProcessWidget.module.scss'
import { PhaseDetails } from 'pages/customWidget/cocaColaChina/process/phaseDetails/PhaseDetails'

export enum Phases {
  Assemble = 'Assemble',
  Explore = 'Explore',
  Create = 'Create',
  Shape = 'Shape',
  Produce = 'Produce',
  Learn = 'Learn',
}

export const CocaColaChinaProcessWidget = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <div className={styles.root}>
      <Flex
        direction="column"
        justify="center"
        className={styles.pageContent}
        data-testid="cocacola-china-process-widget"
      >
        <Flex direction="column" justify="center">
          <Flex justify="center" className={styles.schemeWrapper} gap={16}>
            <div className={clsx(styles.blurOverlay, { [styles.blurOverlayActive]: activePhase })} />
            <div className={clsx(styles.discWrapper, styles.assembleOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.assembleDisc, activePhase === Phases.Assemble && styles.assembleDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Assemble)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Assemble ? (
                  <div className={styles.assembleDetailsRescale} data-testid="assemble-phase-title">
                    <Flex direction="column" justify="center" align="center" className={styles.assembleDetails}>
                      <PhaseDetails index={1} title="Assemble">
                        <WppTypography
                          type="s-body"
                          className={clsx(styles.redTypography, styles.centeredText, styles.prewrap)}
                        >
                          {'Assemble the right team to solve\nthe problem'}
                        </WppTypography>
                        <div>
                          <Flex gap={4} justify="center">
                            <ListIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Output
                            </WppTypography>
                          </Flex>
                          <ul className={styles.stepsList}>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Campaign charter
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Resources assignment
                              </WppTypography>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <Flex gap={4} justify="center">
                            <ToolsIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Tools
                            </WppTypography>
                          </Flex>
                          <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                            Project Brief
                          </WppTypography>
                        </div>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography
                    type="3xl-heading"
                    className={styles.discInactiveTitle}
                    data-testid="assemble-phase-inactive-title"
                  >
                    Assemble
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.assembleDecorativeDisc1} />
              <div className={styles.assembleDecorativeDisc2} />
              <div className={styles.assembleDecorativeDisc3} />
              <div className={styles.assembleDecorativeDisc4} />
              <div
                className={clsx(
                  styles.assembleDecorativeActiveDisc,
                  activePhase === Phases.Assemble && styles.noOpacity,
                )}
              />
            </div>
            <div className={styles.discWrapper}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.exploreDisc, activePhase === Phases.Explore && styles.exploreDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Explore)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Explore ? (
                  <div className={styles.exploreDetailsRescale} data-testid="explore-phase-title">
                    <Flex direction="column" justify="center" align="center" className={styles.exploreDetails}>
                      <PhaseDetails index={2} title="Explore">
                        <WppTypography
                          type="s-body"
                          className={clsx(
                            styles.redTypography,
                            styles.centeredText,
                            styles.prewrap,
                            styles.phaseDescription,
                          )}
                        >
                          {'Define the consumer and space to win\nthen write the plan for how to win it'}
                        </WppTypography>
                        <div>
                          <Flex gap={4} justify="center">
                            <ListIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Output
                            </WppTypography>
                          </Flex>
                          <ul className={styles.stepsList}>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Audience Planning
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Strategy Definition
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Measurement Plan
                              </WppTypography>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <Flex gap={4} justify="center">
                            <ToolsIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Tools
                            </WppTypography>
                          </Flex>
                          <Flex direction="column" align="center">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://apps.etelmar.asia/PRD_SurveyTimeH03/Login"
                            >
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                SHJ (ShanHaiJin)
                              </WppTypography>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.gwi.com/">
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                GWI (GlobalWebIndex)
                              </WppTypography>
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://data.iresearch.com.cn/iRView.shtml"
                            >
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                iResearch
                              </WppTypography>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://bavculturerank.vmlyr.com/ ">
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                BAV
                              </WppTypography>
                            </a>
                          </Flex>
                        </div>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography
                    type="3xl-heading"
                    className={styles.discInactiveTitle}
                    data-testid="explore-phase-inactive-title"
                  >
                    Explore
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.exploreDecorativeDisc1} />
              <div className={styles.exploreDecorativeDisc2} />
              <div className={styles.exploreDecorativeDisc3} />
              <div className={styles.exploreDecorativeDisc4} />
              <div className={styles.exploreDecorativeDisc5} />
              <div
                className={clsx(
                  styles.exploreDecorativeActiveDisc1,
                  activePhase === Phases.Explore && styles.noOpacity,
                )}
              />
              <div
                className={clsx(
                  styles.exploreDecorativeActiveDisc2,
                  activePhase === Phases.Explore && styles.noOpacity,
                )}
              />
            </div>
            <div className={clsx(styles.discWrapper, styles.createOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.createDisc, activePhase === Phases.Create && styles.createDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Create)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Create ? (
                  <div className={styles.createDetailsRescale} data-testid="create-phase-title">
                    <Flex direction="column" justify="center" align="center" className={styles.createDetails}>
                      <PhaseDetails index={3} title="Create">
                        <WppTypography
                          type="s-body"
                          className={clsx(
                            styles.redTypography,
                            styles.centeredText,
                            styles.prewrap,
                            styles.phaseDescription,
                          )}
                        >
                          {'Design the experience idea\nand validate it'}
                        </WppTypography>
                        <div>
                          <Flex gap={4} justify="center">
                            <ListIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Output
                            </WppTypography>
                          </Flex>
                          <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                            Core concept development
                          </WppTypography>
                        </div>
                        <div>
                          <Flex gap={4} justify="center">
                            <ToolsIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Tools
                            </WppTypography>
                          </Flex>
                          <Flex direction="column" align="center">
                            <a target="_blank" rel="noopener noreferrer" href="https://imagine.wpp.ai/login ">
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                Imagine
                              </WppTypography>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://dawm.wpptech.com/login ">
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                DAWN
                              </WppTypography>
                            </a>
                          </Flex>
                        </div>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography
                    type="3xl-heading"
                    className={styles.discInactiveTitle}
                    data-testid="create-phase-inactive-title"
                  >
                    Create
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.createDecorativeDisc1} />
              <div className={styles.createDecorativeDisc2} />
              <div className={styles.createDecorativeDisc3} />
              <div className={styles.createDecorativeDisc4} />
              <div className={styles.createDecorativeDisc5} />
              <div
                className={clsx(styles.createDecorativeActiveDisc1, activePhase === Phases.Create && styles.noOpacity)}
              />
              <div
                className={clsx(styles.createDecorativeActiveDisc2, activePhase === Phases.Create && styles.noOpacity)}
              />
            </div>
            <div className={clsx(styles.discWrapper, styles.shapeOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.shapeDisc, activePhase === Phases.Shape && styles.shapeDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Shape)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Shape ? (
                  <div className={styles.shapeDetailsRescale} data-testid="shape-phase-title">
                    <Flex direction="column" justify="center" align="center" className={styles.shapeDetails}>
                      <PhaseDetails index={4} title="Shape">
                        <WppTypography
                          type="s-body"
                          className={clsx(
                            styles.redTypography,
                            styles.centeredText,
                            styles.prewrap,
                            styles.phaseDescription,
                          )}
                        >
                          {'Use co-creation to shape experiences.\nPlan channels and production'}
                        </WppTypography>
                        <div>
                          <Flex gap={4} justify="center">
                            <ListIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Output
                            </WppTypography>
                          </Flex>
                          <ul className={styles.stepsList}>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Audience planning
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Matrix planning
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Asset list finalisation
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Channel execution
                              </WppTypography>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <Flex gap={4} justify="center">
                            <ToolsIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Tools
                            </WppTypography>
                          </Flex>
                          <Flex direction="column" align="center">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://translator.os.choreograph.cn/enter/translator"
                            >
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                Translator
                              </WppTypography>
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://audex.os.choreograph.cn/techHub/introduce/Audience%20Explorer"
                            >
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                Audience Explorer
                              </WppTypography>
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://modall.os.choreograph.cn/techHub/custom/modAll/index"
                            >
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                ModAll
                              </WppTypography>
                            </a>
                          </Flex>
                        </div>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography
                    type="3xl-heading"
                    className={styles.discInactiveTitle}
                    data-testid="shape-phase-inactive-title"
                  >
                    Shape
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.shapeDecorativeDisc1} />
              <div
                className={clsx(styles.shapeDecorativeActiveDisc1, activePhase === Phases.Shape && styles.noOpacity)}
              />
              <div
                className={clsx(styles.shapeDecorativeActiveDisc2, activePhase === Phases.Shape && styles.noOpacity)}
              />
              <div
                className={clsx(styles.shapeDecorativeActiveDisc3, activePhase === Phases.Shape && styles.noOpacity)}
              />
            </div>
            <div className={clsx(styles.discWrapper, styles.produceOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.produceDisc, activePhase === Phases.Produce && styles.produceDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Produce)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Produce ? (
                  <div className={styles.produceDetailsRescale} data-testid="produce-phase-title">
                    <Flex direction="column" justify="center" align="center" className={styles.produceDetails}>
                      <PhaseDetails index={5} title="Produce">
                        <WppTypography
                          type="s-body"
                          className={clsx(
                            styles.redTypography,
                            styles.centeredText,
                            styles.prewrap,
                            styles.phaseDescription,
                          )}
                        >
                          {'Produce final assets\nStreamline OU and Activation Planning'}
                        </WppTypography>
                        <div>
                          <Flex gap={4} justify="center">
                            <ListIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Output
                            </WppTypography>
                          </Flex>
                          <ul className={styles.stepsList}>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Asset planning
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Pre-flight testing
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Asset production
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Toolkit development
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Localisation
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Final clearance
                              </WppTypography>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <Flex gap={4} justify="center">
                            <ToolsIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Tools
                            </WppTypography>
                          </Flex>
                          <Flex direction="column" align="center">
                            <a target="_blank" rel="noopener noreferrer" href="https://mediacom.planningos.app/login">
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                Architect
                              </WppTypography>
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://copilot.os.choreograph.cn/enter/copilot"
                            >
                              <WppTypography
                                type="s-body"
                                className={clsx(styles.redTypography, styles.hoverUnderline)}
                              >
                                Copilot
                              </WppTypography>
                            </a>
                          </Flex>
                        </div>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography
                    type="3xl-heading"
                    className={styles.discInactiveTitle}
                    data-testid="produce-phase-inactive-title"
                  >
                    Produce
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.produceDecorativeDisc1} />
              <div className={styles.produceDecorativeDisc2} />
              <div
                className={clsx(
                  styles.produceDecorativeActiveDisc1,
                  activePhase === Phases.Produce && styles.noOpacity,
                )}
              />
              <div
                className={clsx(
                  styles.produceDecorativeActiveDisc2,
                  activePhase === Phases.Produce && styles.noOpacity,
                )}
              />
              <div
                className={clsx(
                  styles.produceDecorativeActiveDisc3,
                  activePhase === Phases.Produce && styles.noOpacity,
                )}
              />
              <div
                className={clsx(
                  styles.produceDecorativeActiveDisc4,
                  activePhase === Phases.Produce && styles.noOpacity,
                )}
              />
            </div>
            <div className={clsx(styles.discWrapper, styles.learnOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.learnDisc, activePhase === Phases.Learn && styles.learnDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Learn)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Learn ? (
                  <div className={styles.learnDetailsRescale} data-testid="learn-phase-title">
                    <Flex direction="column" justify="center" align="center" className={styles.learnDetails}>
                      <PhaseDetails index={6} title="Learn">
                        <WppTypography
                          type="s-body"
                          className={clsx(
                            styles.redTypography,
                            styles.centeredText,
                            styles.prewrap,
                            styles.phaseDescription,
                          )}
                        >
                          {'Continuous feedback loops\nand optimisation. Tracking of W+'}
                        </WppTypography>
                        <div>
                          <Flex gap={4} justify="center">
                            <ListIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Output
                            </WppTypography>
                          </Flex>
                          <ul className={styles.stepsList}>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Activation
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Mid-flight optimisation
                              </WppTypography>
                            </li>
                            <li>
                              <WppTypography type="s-body" className={clsx(styles.redTypography)}>
                                Experience learnings
                              </WppTypography>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <Flex gap={4} justify="center">
                            <ToolsIcon />
                            <WppTypography type="s-strong" className={styles.redTypography}>
                              Tools
                            </WppTypography>
                          </Flex>
                          <Flex direction="column" align="center">
                            <ul className={styles.stepsList}>
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://app.powerbi.com/groups/me/reports/236a55e7-c29c-4599-ae86-061c1329134e/ReportSection5ab19ecbe23504ad547d?ctid=86cbe1bb-213f-4271-b174-bd59d03c87a3&experience=power-bi"
                                >
                                  <WppTypography
                                    type="s-body"
                                    className={clsx(styles.redTypography, styles.hoverUnderline, styles.prewrap)}
                                  >
                                    Report Sync Engine
                                  </WppTypography>
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://insidemedia.sharepoint.com/sites/TCCCGCMOUcopy/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=6SeEaV&cid=19b996e8%2D0d6b%2D447a%2D8233%2D85644ce33a0d&RootFolder=%2Fsites%2FTCCCGCMOUcopy%2FShared%20Documents%2FGeneral%2FFiles%20Sharing%2FGC%20E2E%20Campaign%20Showcases&FolderCTID=0x0120009FA848953C973340AEBB31D139955BCB"
                                >
                                  <WppTypography
                                    type="s-body"
                                    className={clsx(styles.redTypography, styles.hoverUnderline)}
                                  >
                                    Awarded cases
                                  </WppTypography>
                                </a>
                              </li>
                            </ul>
                          </Flex>
                        </div>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography
                    type="3xl-heading"
                    className={styles.discInactiveTitle}
                    data-testid="learn-phase-inactive-title"
                  >
                    Learn
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.learnDecorativeDisc1} />
              <div className={styles.learnDecorativeDisc2} />
              <div className={styles.learnDecorativeDisc3} />
              <div
                className={clsx(styles.learnDecorativeActiveDisc1, activePhase === Phases.Learn && styles.noOpacity)}
              />
              <div
                className={clsx(styles.learnDecorativeActiveDisc2, activePhase === Phases.Learn && styles.noOpacity)}
              />
              <div
                className={clsx(styles.learnDecorativeActiveDisc3, activePhase === Phases.Learn && styles.noOpacity)}
              />
            </div>
          </Flex>
        </Flex>
      </Flex>
      {/* <div className={clsx(styles.blurOverlay, { [styles.blurOverlayActive]: activePhase })} /> */}
    </div>
  )
}
