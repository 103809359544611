import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/pfizer/PfizerProcess.module.scss'

export const PfizerProcess = () => {
  const [hoverEl, setHover] = useState('all')
  const checkIsHover = useCallback(
    (process: string) => {
      if (hoverEl === 'all') {
        return
      }
      return hoverEl !== process && 'all' ? styles.shadow : null
    },
    [hoverEl],
  )

  return (
    <Flex align="center" justify="center" className={clsx(styles.container, hoverEl)} data-testid="pfizer-process">
      <div className={styles.toolsDescriptions}>
        {hoverEl === 'business' && (
          <Flex direction="column" className={styles.businessTool}>
            <WppTypography type="s-strong" className={styles.businessToolLabel}>
              Entry Point
            </WppTypography>
            <div className={styles.businessToolLine} />
            <WppTypography type="s-body" className={styles.businessToolDescription}>
              Sets up each individual use case - following a global criteria for success
            </WppTypography>
          </Flex>
        )}

        {hoverEl === 'ideas' && (
          <Flex direction="column" align="end" className={styles.ideasTool}>
            <WppTypography type="s-strong" className={styles.ideasToolLabel}>
              Insights and Ideas
            </WppTypography>
            <div className={styles.ideasToolLine} />
            <WppTypography type="s-body" className={styles.ideasToolDescription}>
              A data driven framework that elevates relevant, contextual insight pull through into GTM strategy,
              creative and tactical planning
            </WppTypography>
          </Flex>
        )}

        {hoverEl === 'media' && (
          <Flex direction="column" align="end" className={styles.mediaTool}>
            <WppTypography type="s-body" className={styles.mediaToolDescription}>
              One unified, accessible, modular content enablement platform that promotes faster, more efficient
              decisioning & automation through to activation
            </WppTypography>
            <div className={styles.mediaToolLine} />
            <WppTypography type="s-strong" className={styles.mediaToolLabel}>
              Content, Production, Media and Activation
            </WppTypography>
          </Flex>
        )}

        {hoverEl === 'optimisation' && (
          <Flex direction="column" className={styles.optimisationTool}>
            <WppTypography type="s-strong" className={styles.optimisationToolLabel}>
              Optimisation and Measurment
            </WppTypography>
            <div className={styles.optimisationToolLine} />
            <WppTypography type="s-body" className={styles.optimisationToolDescription}>
              A rigorous measurement and optimzation strategy that capitalizes on measureable KPIs from each phase in a
              CLM environment
            </WppTypography>
          </Flex>
        )}

        {hoverEl === 'choreograph' && (
          <Flex direction="column" className={styles.choreographTool}>
            <WppTypography type="s-strong" className={styles.choreographToolLabel}>
              Choreograph
            </WppTypography>
            <div className={styles.choreographToolLine} />
            <WppTypography type="s-body" className={styles.choreographToolDescription}>
              One global data platform for Pfizer operating on Patient data, HCP data, Context data and Pfizer data
            </WppTypography>
          </Flex>
        )}
      </div>
      <WppCard className={styles.circle} onMouseLeave={() => setHover('all')}>
        <Link
          to="business"
          className={clsx(styles.business, styles.sector, checkIsHover('business'))}
          onMouseOver={() => setHover('business')}
        >
          <div className={styles.businessLabel} />
          <div className={styles.businessLine} />
        </Link>
        <Link
          className={clsx(styles.ideas, styles.sector, checkIsHover('ideas'))}
          onMouseOver={() => setHover('ideas')}
          to="ideas"
        >
          <div className={styles.ideasLabel} />
          <div className={styles.ideasLine} />
        </Link>
        <Link
          to="media"
          className={clsx(styles.media, styles.sector, checkIsHover('media'))}
          onMouseOver={() => setHover('media')}
        >
          <div className={styles.mediaLabel} />
          <div className={styles.mediaLine} />
        </Link>
        <Link
          to="optimisation"
          className={clsx(styles.optimisation, styles.sector, checkIsHover('optimisation'))}
          onMouseOver={() => setHover('optimisation')}
        >
          <div className={styles.optimisationLabel} />
          <div className={styles.optimisationLine} />
        </Link>
        <Flex
          align="center"
          justify="center"
          className={styles.circleContentWrapper}
          onMouseOver={() => setHover('choreograph')}
        >
          <Flex
            align="center"
            justify="center"
            className={clsx(
              hoverEl === 'all' || hoverEl === 'choreograph' ? null : styles.activeCircle,
              styles.centerCircle,
            )}
          >
            <div className={clsx(styles.activePosition, styles[`${hoverEl}Hover`])} />
            <Flex align="center" justify="center" className={styles.centerContent}>
              <div className={styles.logo} />
            </Flex>
          </Flex>
        </Flex>
      </WppCard>
    </Flex>
  )
}
