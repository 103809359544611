import { WppTypography, WppButton, WppRichtextHtml } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular2/Circular2Process.module.scss'
import { Circular2Svg } from 'pages/processBuilder/templates/circular2/Circular2Svg'
import { ProcessFormConfig } from 'types/process/process'

export const Circular2Process = ({ config, isMichelin }: { config: ProcessFormConfig; isMichelin: boolean }) => {
  const [activeGroup, setActiveGroup] = useState<MayBeNull<number>>(null)
  const [activePhase, setActivePhase] = useState<MayBeNull<number>>(null)

  const handleGroupEnter = useCallback(
    (phase: number) => () => {
      !activePhase && setActiveGroup(phase)
    },
    [activePhase],
  )

  const handleGroupLeave = useCallback(() => {
    setActiveGroup(null)
  }, [])

  const handlePhaseEnter = useCallback(
    (point: number) => () => {
      setActivePhase(point)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <>
      <Flex
        align="center"
        justify="center"
        className={styles.root}
        id="processRoot"
        data-testid="circular-two-process-container"
      >
        <Circular2Svg
          onGroupEnter={handleGroupEnter}
          onGroupLeave={handleGroupLeave}
          onPhaseEnter={handlePhaseEnter}
          onPhaseLeave={handlePhaseLeave}
          activeGroup={activeGroup}
          activePhase={activePhase}
          config={config}
          isMichelin={isMichelin}
        />

        {typeof activeGroup === 'number' && !Number.isInteger(activePhase) && (
          <Flex className={styles.groupInfo} direction="column" gap={10} data-testid="active-group-container">
            <WppTypography
              type="2xl-heading"
              className={styles.descriptionTitle}
              style={{ color: config.groups?.[activeGroup]?.colors?.[4] || '#001023' }}
            >
              {config.groups?.[activeGroup]?.title}
            </WppTypography>
            {config.groups?.[activeGroup]?.description && (
              <WppRichtextHtml
                className={clsx(styles.description, 'richTextDescription')}
                style={{ color: config.groups?.[activeGroup]?.colors?.[4] || '#001023' }}
                value={config.groups?.[activeGroup].description as string}
              />
            )}
          </Flex>
        )}

        {config?.button?.isVisible && (
          <Link to={config?.button?.url || ''} className={styles.projectLink} data-testid="circular-process-btn">
            <WppButton
              className={styles.projectButton}
              style={{ background: config?.button?.colors?.[0], color: config?.button?.colors?.[1] }}
            >
              {config?.button?.title}
            </WppButton>
          </Link>
        )}
      </Flex>
      <div className={styles.popupRoot} id="phasePopupRoot" />
    </>
  )
}
