import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import tigerLogoImgSrc from 'pages/customProcess/components/tigerBrands/assets/logo.png'
import { PhaseCard } from 'pages/customProcess/components/tigerBrands/components/phaseCard/PhaseCard'
import { processPhasesData } from 'pages/customProcess/components/tigerBrands/TigerBrandsConfig'
import styles from 'pages/customProcess/components/tigerBrands/TigerBrandsProcess.module.scss'

export const TigerBrandsProcess = () => {
  const [activePhaseIndex, setActivePhaseIndex] = useState<MayBeNull<number>>(null)

  const handlePhaseEnter = useCallback(
    (phaseIndex: number) => () => {
      setActivePhaseIndex(phaseIndex)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhaseIndex(null)
  }, [])

  return (
    <div className={styles.root} data-testid="tigerBrands-process">
      <Flex justify="between" gap={16} className={styles.header}>
        <div>
          <WppTypography type="4xl-display" className={styles.title}>
            Accelerated Growth Planning Process
          </WppTypography>
          <WppTypography type="l-body" className={styles.title}>
            Building desire through multi-platform, co-ordinated, integrated and distinctive comms experiences
          </WppTypography>
        </div>
        <div>
          <img src={tigerLogoImgSrc} alt="Tiger Brands logo" className={styles.logo} />
        </div>
      </Flex>
      <Flex gap={4}>
        {processPhasesData.map((phase, index) => (
          <PhaseCard
            key={index}
            index={index + 1}
            isActive={index + 1 === activePhaseIndex}
            onPhaseEnter={handlePhaseEnter}
            onPhaseLeave={handlePhaseLeave}
            link={phase.link}
            color={phase.color}
            title={phase.title}
            description={phase.description}
          />
        ))}
      </Flex>
    </div>
  )
}
